import Server from '../../api/server'
import {
  DELETE_INTEGRATION_ROUTE,
  GET_INTEGRATIONS_ROUTE,
  UPDATE_INTEGRATION_CONFIG_ROUTE,
  UPDATE_IVRIFY_INTEGRATION_CONFIG_ROUTE,
} from '../../strings/api-consts'
import { CustomThunkDispatch } from '../../types/UserTypes'
import * as Sentry from '@sentry/react'
import { setIntegrationsList } from './integrations.slice'
import { DeleteIntegrationPayload, Integration, UpdateIntegrationPayload } from '../../types/IntegrationTypes'

const server = new Server()

export const fetchIntegrationsList = () => async (dispatch: CustomThunkDispatch) => {
  const url = GET_INTEGRATIONS_ROUTE

  return server
    .get(url, dispatch)
    .then((integrationsList: Integration[]) => {
      dispatch(setIntegrationsList(integrationsList))
      return integrationsList
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      dispatch(setIntegrationsList(undefined))
      return undefined
    })
}

export const updateIntegrationsConfig =
  (payload: UpdateIntegrationPayload) => async (dispatch: CustomThunkDispatch) => {
    const url = UPDATE_INTEGRATION_CONFIG_ROUTE

    return server
      .post(url, payload, dispatch)
      .then(() => {
        return true
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
        return false
      })
  }

export const updateIverifyConfig = (payload: UpdateIntegrationPayload) => async (dispatch: CustomThunkDispatch) => {
  const url = UPDATE_IVRIFY_INTEGRATION_CONFIG_ROUTE

  return server
    .post(url, payload, dispatch)
    .then(() => {
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const deleteIntegration = (payload: DeleteIntegrationPayload) => async (dispatch: CustomThunkDispatch) => {
  const url = DELETE_INTEGRATION_ROUTE

  return server
    .delete(url, dispatch, payload)
    .then(() => {
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}
