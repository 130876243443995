import { Badge } from '@radix-ui/themes'
import chroma from 'chroma-js'
import React from 'react'

interface PillProps {
  label: string
  bgColor?: string
  color?: string
  classNames?: string
}

const Pill: React.FC<PillProps> = ({ color, bgColor, label, classNames }) => {
  return (
    <Badge
      style={{
        color: color && chroma.valid(color) ? chroma(color).css() : color || '',
        backgroundColor: bgColor && chroma.valid(bgColor) ? chroma(bgColor).css() : bgColor || '',
      }}
      className={`rounded-2xl border px-3 py-1 ${classNames}`}
    >
      {label}
    </Badge>
  )
}

export default Pill
