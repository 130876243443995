import { useEffect, useState } from 'react'
import { SelectOption } from '../../types/DropDownTypes'
import { IconSquare, IconSquareCheckFilled } from '@tabler/icons-react'

interface IRadioButtonGroupProps {
  options: SelectOption[]
  value: SelectOption[]
  onChangeHandler?: (options: SelectOption[], value?: SelectOption) => void
}

export const RadioButtonGroup = ({ options, value, onChangeHandler }: IRadioButtonGroupProps) => {
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleOnChange = (checked: boolean, option: SelectOption) => {
    const updatedValues = checked
      ? [...(currentValue || []), option]
      : currentValue?.filter((o) => o.value !== option.value) || []
    if (onChangeHandler) {
      onChangeHandler(updatedValues, checked ? option : undefined)
    }
  }

  const isOptionSelected = (option: SelectOption) => {
    return currentValue?.some((o) => o.value === option.value)
  }

  return (
    <div className="flex flex-col gap-2">
      {options.map((option) => (
        <div
          key={option.value}
          className={`flex items-center gap-3 p-2 rounded border border-InputBorderGrey ${option.disabled ? 'bg-neutral-3 cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={() => {
            if (option.disabled) return
            const isSelected = isOptionSelected(option)
            handleOnChange(!isSelected, option)
          }}
        >
          {isOptionSelected(option) ? (
            <IconSquareCheckFilled className={`${option.disabled ? 'text-neutral-8' : 'text-PurpleBlue-9'}`} />
          ) : (
            <IconSquare className="w-6 h-6 text-InputBorderGrey text-primary" />
          )}
          <div className="flex flex-col leading-tight">
            <p className="font-medium text-sm">{option.label}</p>
            <span className="text-xs">{option.description}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default RadioButtonGroup
