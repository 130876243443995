import { createColumnHelper } from '@tanstack/react-table'
import { Admin } from '../../types/SettingsType'
import { Button } from '@radix-ui/themes'
import Pill from '../pill/Pill'

export const adminColumns = (
  handleViewAdmin: (data: Admin) => void,
) => {
  const columnHelper = createColumnHelper<Admin>()

  const editButton = (admin: Admin) => {
    return (
      <Button
        size={'2'}
        className="cursor-pointer"
        onClick={() => {
          handleViewAdmin(admin)
        }}
        variant="outline"
      >
        Edit
      </Button>
    )
  }

  return [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
    }),
    columnHelper.accessor('roles', {
      header: 'Roles',
      cell: (row) => {
        return (
          <div className="flex flex-wrap gap-2 p-1">
            {row.row.original.roles
              .slice().sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
              .map((role) => (
                <div key={`${row.row.original.email}_${role.value}`} className="flex flex">
                  <Pill color={role.color} bgColor={role.bgColor} label={role.label} />
                </div>
              ))}
          </div>
        )
      },
    }),
    {
      id: 'actions',
      cell: (row: { row: { original: Admin } }) => (
        <>
          <div className="w-100 flex gap-2 justify-end">
            {editButton(row.row.original)}
          </div>
        </>
      ),
    },
  ]
}
