import { useEffect, useState } from 'react'
import DataGrid from '../data-grid/DataGrid'
import { useAppDispatch } from '../../reducers/store'
import { fetchAdmins, resendAdminInvite, selectSettings } from '../../reducers/settings'
import { useSelector } from 'react-redux'
import { adminColumns } from './AdminColumns'
import { Admin } from '../../types/SettingsType'
import toast from 'react-hot-toast'
import DeleteAdminModal from './DeleteAdminModal'
import { RoleType, SessionState } from '../../types/UserTypes'
import Popout from '../popout/Popout'
import { Button, SegmentedControl } from '@radix-ui/themes'
import { rolesOptions } from '../../strings/dropdown-options'

function AdminSettings() {
  const { admins } = useSelector(selectSettings)
  const di = useAppDispatch()
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [showDeleteAdminModal, setShowDeleteAdminModal] = useState<boolean>(false)
  const [showEditPopout, setShowPopout] = useState<boolean>(false)
  const [popoutData, setPopoutData] = useState<Admin | undefined>(undefined)
  const [filteredData, setFilteredData] = useState<Admin[] | undefined>(undefined)
  const [inviteStatus, setInviteStatus] = useState<string>('ACTIVE')
  const currentSession = JSON.parse(localStorage.getItem('session') as string) as SessionState

  useEffect(() => {
    di(fetchAdmins())
  }, [])

  useEffect(() => {
    if (admins || (admins && (admins as Admin[]).length === 0)) {
      setFilteredData(admins.filter((admin) => admin.inviteAccepted === (inviteStatus === 'ACTIVE')))
      setDataLoading(false)
    } else if (!admins) {
      setDataLoading(true)
    }
  }, [admins, inviteStatus])

  const handleResendInvite = async (adminDetails: Admin) => {
    const sent = await di(resendAdminInvite(adminDetails))
    if (!sent) {
      showToast('An error occurred while resending the invite', '❌')
    } else {
      showToast(`An invite has been resent to ${adminDetails.name} at ${adminDetails.email}`, '✅')
    }
  }

  const handleDeleteAdmin = async (admin: Admin) => {
    if (admin.email === currentSession.user.email) {
      showToast('You cannot remove yourself as admin', '❌')
    } else {
      setShowDeleteAdminModal(true)
    }
  }

  const handleViewAdmin = (admin?: Admin) => {
    setShowPopout(true)
    setPopoutData(
      admin ? admin : { name: '', email: '', roles: rolesOptions.filter((role) => role.value === RoleType.GENERAL_ADMIN) }
    )
  }

  const showToast = (message: string, emoji: string) =>
    toast(message, {
      duration: 6000,
      icon: emoji,
    })

  const updateSection = (admin: Admin) => {
    admin.inviteAccepted || currentSession?.organization.ssoProvider
      ? setInviteStatus('ACTIVE')
      : setInviteStatus('INVITED')
  }

  const triggerEvent = (event?: string, admin?: Admin) => {
    switch (event) {
      case 'invite':
        handleResendInvite(admin!)
        break
      case 'delete':
        handleDeleteAdmin(admin!)
        break
      case 'update':
        updateSection(admin!)
        break
      default:
        break
    }
  }

  return (
    <>
      <div className="flex justify-between">
        <SegmentedControl.Root
          defaultValue="ACTIVE"
          value={inviteStatus}
          className="max-w-[300px] min-h-[38px] text-neutral-11 bg-neutral-3 text-gray-600"
          onValueChange={(value) => setInviteStatus(value)}
        >
          <SegmentedControl.Item value="ACTIVE" className="px-8">
            Active
          </SegmentedControl.Item>
          <SegmentedControl.Item value="INVITED" className="px-8">
            Invited
          </SegmentedControl.Item>
        </SegmentedControl.Root>
        <Button
          className="bg-PurpleBlue-10 h-[38px] ml-4 cursor-pointer"
          onClick={() => {
            handleViewAdmin()
          }}
        >
          Invite Admin
        </Button>
      </div>
      <div className="w-full h-[calc(100vh_-_80px)] overflow-auto flex flex-col gap-4">
        <DataGrid columns={adminColumns(handleViewAdmin)} data={filteredData} loading={dataLoading} />
      </div>
      {showDeleteAdminModal && popoutData && (
        <DeleteAdminModal
          triggerEvent={setShowPopout}
          setShowModal={setShowDeleteAdminModal}
          deleteAdminEmail={popoutData.email}
        />
      )}
      {showEditPopout && (
        <Popout
          setShowPopout={setShowPopout}
          triggerEvent={triggerEvent}
          contentScreen="ADMIN"
          heightOffset={0}
          popoutData={popoutData}
        />
      )}
    </>
  )
}

export default AdminSettings
