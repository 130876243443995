import toast from 'react-hot-toast'
import { ALERTS_ROUTE, ALERTS_CLOSE_ROUTE, ALERT_REPORT_ROUTE } from '../../strings/api-consts'
import { AlertResponse, ScanMethod } from '../../types/AlertTypes'
import { SelectOption } from '../../types/DropDownTypes'
import { setAlerts, setCurrentPage, setTotalPages } from './alerts.slice'
import { ThreatHunterUsersResponse } from '../../types/SettingsType'
import { alertOptions } from '../../strings/dropdown-options'
import { CustomThunkDispatch } from '../../types/UserTypes'
import * as Sentry from '@sentry/react'
import Server from '../../api/server'

const server = new Server()

export const fetchAlerts =
  (
    pageNumber: number,
    pageSize: number,
    statusFilter: SelectOption[],
    threatHunterUsers: ThreatHunterUsersResponse | { error: string },
    sortOrder?: string,
    sortColumn?: string
  ) =>
  async (dispatch: CustomThunkDispatch) => {
    let url = `${ALERTS_ROUTE}?page=${pageNumber}&pageSize=${pageSize}`

    if (statusFilter.length > 0 && statusFilter.length !== alertOptions.length) {
      const statusParam = statusFilter.map((status) => `&status=${status.value}`).join('')
      url += statusParam
    }

    if (sortColumn) {
      url += `&sortColumn=${sortColumn}`
      if (sortOrder) {
        url += `&sortOrder=${sortOrder}`
      }
    }

    await server
      .get(url, dispatch)
      .then((res: AlertResponse) => {
        const updatedScans = res.alerts.map((alert) => {
          if (
            alert.scanMethod === ScanMethod.THREAT_HUNTER &&
            threatHunterUsers &&
            'threatHunterUsers' in threatHunterUsers
          ) {
            const threatHunterUser = threatHunterUsers.threatHunterUsers.find(
              (user) => user.accessCode === alert.scannedByAccessCode
            )
            if (threatHunterUser) {
              return { ...alert, scannedBy: threatHunterUser.email }
            }
          }
          return alert
        })
        dispatch(setCurrentPage(!pageNumber ? 0 : pageNumber - 1))
        dispatch(setAlerts(updatedScans))
        dispatch(setTotalPages(res.totalPages))
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
      })
  }

export const closeAlert = (alertId: number, scanId: string) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(ALERTS_CLOSE_ROUTE, { id: alertId, scanId: scanId }, dispatch)
    .then(() => {
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const generateAndDownloadReport = (scanId: string, isThreatHunter: boolean) => (dispatch: CustomThunkDispatch) => {
  return new Promise((resolve, reject) => {
    const pollForReport = async () => {
      return server
        .post(ALERT_REPORT_ROUTE, { scanId: scanId, isThreatHunter: isThreatHunter }, dispatch)
        .then((res) => {
          if (!res) {
            throw new Error('Response is undefined')
          }
          return res.json()
        })
        .then((res) => {
          if (res.message.includes('Report already exists')) {
            // Download the report
            const link = document.createElement('a')
            link.href = res.download_url
            link.download = ''
            link.style.display = 'none'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            toast.success('Report has been successfully generated', { duration: 6000 })
            resolve(true)
          } else if (res.statusCode !== 200) {
            toast.error('There was an issue generating the report', { duration: 6000 })
            reject(false)
          } else {
            setTimeout(pollForReport, 10000)
          }
        })
        .catch(() => {
          toast.error('There was an issue generating the report', { duration: 6000 })
          reject(false)
        })
    }
    pollForReport()
  })
}
