import { RoleType, SessionState } from '../types/UserTypes'

export const getRoles = function (session: SessionState | undefined): string[] | undefined {
  return session?.user.roles?.split(',').map((role: string) => role.trim())
}

export const permitsRole = (roles: string[] | undefined, permittedRoles: RoleType[] = []) => {
  permittedRoles.push(RoleType.SUPER_ADMIN)
  return permittedRoles.some((role) => roles?.includes(role))
}
