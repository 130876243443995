import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { fetchEnrollmentEmailTemplate, putEnrollmentEmailTemplate, selectSettings } from '../../reducers/settings'
import { useAppDispatch } from '../../reducers/store'
import { useSelector } from 'react-redux'
import { Button } from '@radix-ui/themes'
import { Input } from '../input/Input'

interface IDisconnectModalProps {
  setShowModal: (show: boolean) => void
}

function NotificationModal(props: IDisconnectModalProps) {
  const { setShowModal } = props
  const di = useAppDispatch()
  const { emailEnrollmentTemplate } = useSelector(selectSettings)
  const [subject, setSubject] = useState<string>('')
  const [body, setBody] = useState<string>('')
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)

  const saveTemplate = async () => {
    const templatePayload = {
      subject: subject,
      body: body,
      type: 'OrgEnrollment',
    }
    const savedTemplate = await di(putEnrollmentEmailTemplate(templatePayload))
    if (savedTemplate) {
      toast.success('Email template saved successfully', { duration: 6000 })
      setShowModal(false)
    } else {
      toast.error('Failed to save email template', { duration: 6000 })
    }
  }

  useEffect(() => {
    di(fetchEnrollmentEmailTemplate())
  }, [])

  useEffect(() => {
    if (emailEnrollmentTemplate) {
      setSubject(emailEnrollmentTemplate.subject)
      setBody(emailEnrollmentTemplate.body)
    }
  }, [emailEnrollmentTemplate])

  const revertTemplate = () => {
    setSubject('Your iVerify for Organizations enrollment information!')
    setBody('')
  }

  useEffect(() => {
    if (subject !== emailEnrollmentTemplate?.subject || body !== emailEnrollmentTemplate?.body) {
      setUnsavedChanges(true)
    } else {
      setUnsavedChanges(false)
    }
  }, [subject, body])

  const checkIfCanCloseModal = () => {
    if (unsavedChanges) {
      if (confirm('You have unsaved changes. Are you sure you want to close?')) {
        setShowModal(false)
      }
    } else {
      setShowModal(false)
    }
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => checkIfCanCloseModal()}
    >
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] w-[50%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="block px-16">
          <p className="text-2xl font-semibold text-center">Set Custom Enrollment Email</p>
          <p className="mt-4 text-center">
            Create a customized onboarding email and save it as a template to be used on every new onboarded member.
          </p>
          <label className="text-darkGreyBlue font-semibold text-xs block mt-8">SUBJECT (MAX 120 CHARACTERS)</label>
          <Input value={subject} onChange={setSubject} type="text" placeholder="Email Subject" maxLength={120} />
          <label className="text-darkGreyBlue font-semibold text-xs block mt-4">EMAIL BODY (MAX 3000 CHARACTERS)</label>
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            placeholder="Email body..."
            className="w-full h-[200px] p-2 border border-lightGrey rounded focus:outline-none focus:border-blue focus:ring-1 focus:ring-blue"
            maxLength={3000}
          />
        </div>

        <div className="flex justify-between mt-6 px-16">
          <Button
            className="px-4 py-2 rounded-lg cursor-pointer"
            color="red"
            size={'3'}
            onClick={() => checkIfCanCloseModal()}
          >
            Cancel
          </Button>
          <div className="flex">
            {unsavedChanges && <p className="mt-2 mr-2 text-lightGreyBlue">Unsaved Changes</p>}
            <Button
              className="px-4 py-2 mr-2 rounded-lg cursor-pointer"
              size={'3'}
              variant="outline"
              onClick={() => revertTemplate()}
            >
              Revert Template
            </Button>
            <Button
              className="px-4 py-2 text-white rounded-lg cursor-pointer"
              size={'3'}
              onClick={() => saveTemplate()}
            >
              Save Email Template
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationModal
