import { Button } from '@radix-ui/themes'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserSession, selectUser, setCurrentPage } from '../../reducers/users'
import { navItems } from '../../data/nav-items'
import {
  IconActivity,
  IconCloudLock,
  IconDashboard,
  IconDevices,
  IconPlugConnected,
  IconSearch,
  IconUrgent,
  IconUsers,
} from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { getRoles, permitsRole } from '../../utils/roles'
import { RoleType } from '../../types/UserTypes'

function NavMenu() {
  const { session, currentPage } = useSelector(selectUser)
  const dispatch = useDispatch()
  const di = useAppDispatch()
  const roles = getRoles(session)
  const [updatedNavItems, setUpdatedNavItems] = useState(navItems)

  useEffect(() => {
    di(fetchUserSession())
  }, [])

  const selectIcon = (item: string) => {
    switch (item) {
      case 'DASHBOARD':
        return <IconDashboard size={18} />
      case 'ALERTS':
        return <IconUrgent size={18} />
      case 'ACTIVITY':
        return <IconActivity size={18} />
      case 'INVESTIGATIONS':
        return <IconSearch size={18} />
      case 'DEVICES':
        return <IconDevices size={18} />
      case 'MEMBERS':
        return <IconUsers size={18} />
      case 'BLOCKLIST':
        return <IconCloudLock size={18} />
      case 'INTEGRATIONS':
        return <IconPlugConnected size={18} />
      default:
        return <IconDashboard size={18} />
    }
  }

  useEffect(() => {
    if (roles && permitsRole(roles, [RoleType.PLATFORM_MGMT])) {
      setUpdatedNavItems((prevNavItems) => {
        const exists = prevNavItems.some((item) => item.type === 'INTEGRATIONS')
        if (!exists) {
          return [...prevNavItems, { type: 'INTEGRATIONS', display: 'Integrations' }]
        }
        return prevNavItems
      })
    }
  }, [session])

  return (
    <>
      <div className="w-[169px] mt-5">
        {updatedNavItems.map((item) => (
          <Button
            key={item.type}
            variant="solid"
            size="3"
            style={{
              justifyContent: 'left',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: '500',
            }}
            className={`h-10 !w-[176px] ${currentPage === item.type ? 'bg-violet-3 text-PurpleBlue-9' : 'bg-white text-black'} mb-1 text-sm`}
            onClick={() => {
              localStorage.setItem('DASHBOARD_PAGE_COOKIE', item.type)
              dispatch(setCurrentPage(item.type))
            }}
          >
            {selectIcon(item.type)}
            {item.display}
          </Button>
        ))}
      </div>
    </>
  )
}

export default NavMenu
