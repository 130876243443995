import { useEffect, useState } from 'react'
import DataGrid from '../data-grid/DataGrid'
import { useAppDispatch } from '../../reducers/store'
import { fetchAuditLogs, selectSettings } from '../../reducers/settings'
import { useSelector } from 'react-redux'
import { AuditLog } from '../../types/SettingsType'
import { auditColumns } from './AuditColumns'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../strings/api-consts'
import toast from 'react-hot-toast'
import PageSkeleton from '../skeleton/PageSkeleton'
import { Pagination } from '../pagination/Pagination'
import Popout from '../popout/Popout'
import { Input } from '../input/Input'

function AuditLogs() {
  const di = useAppDispatch()
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const { auditLogs, auditLogsTotalPages, auditLogsCurrentPage } = useSelector(selectSettings)
  const [showPopout, setShowPopout] = useState<boolean>(false)
  const [popoutData, setPopoutData] = useState<AuditLog>()
  const [fromDateTime, setFromDateTime] = useState<string>('')
  const [toDateTime, setToDateTime] = useState<string>('')
  const filters = { fromDateTime, toDateTime }

  const showToast = (message: string, emoji: string) =>
    toast(message, {
      duration: 6000,
      position: 'bottom-right',
      icon: emoji,
    })

  useEffect(() => {
    async function getAuditLogs() {
      const success = await di(fetchAuditLogs(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, filters))
      if (!success) {
        showToast('An error occurred while fetching audit logs', '❌')
      }
    }
    getAuditLogs()
  }, [fromDateTime, toDateTime])

  useEffect(() => {
    if (auditLogs || (auditLogs && (auditLogs as AuditLog[]).length === 0)) {
      setDataLoading(false)
    } else if (!auditLogs) {
      setDataLoading(true)
    }
  }, [auditLogs])

  const handlePageClick = async (selectedItem: { selected: number }): Promise<void> => {
    setDataLoading(true)
    const success = await di(fetchAuditLogs(selectedItem.selected + 1, DEFAULT_PAGE_SIZE, filters))
    if (!success) {
      showToast('An error occurred while fetching audit logs', '❌')
    }
  }

  const getLogDetails = (log: AuditLog) => {
    setPopoutData(log)
    setShowPopout(true)
  }

  return (
    <>
      <div className="w-full pb-14 overflow-auto">
        {!dataLoading && (
          <div className="mb-6 flex gap-8 w-full">
            <div className="max-w-1/3">
              <label className="text-darkGreyBlue font-semibold text-xs block uppercase">From</label>
              <Input value={fromDateTime} onChange={setFromDateTime} type="datetime-local" />
            </div>
            <div className="max-w-1/3">
              <label className="text-darkGreyBlue font-semibold text-xs block uppercase">To</label>
              <Input value={toDateTime} onChange={setToDateTime} type="datetime-local" />
            </div>
          </div>
        )}

        {!auditLogs && (
          <div className="flex justify-center items-center min-h-screen">
            <PageSkeleton />
          </div>
        )}
        {auditLogs && auditLogs?.length > 0 && (
          <DataGrid
            columns={auditColumns(getLogDetails)}
            data={auditLogs}
            loading={dataLoading}
            classNames="max-h-[calc(100vh_-_320px)]"
          />
        )}
        <div className="flex justify-center items-center mt-2">
          <Pagination
            totalPages={auditLogsTotalPages}
            forcePage={auditLogsCurrentPage}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
      {auditLogs && auditLogs.length === 0 && (
        <div className="flex items-center justify-center">
          <div className="text-center">
            <p className="font-bold">No Audit Logs</p>
            <p className="text-sm">You have no Audit Logs to view</p>
          </div>
        </div>
      )}
      {showPopout && (
        <Popout heightOffset={0} setShowPopout={setShowPopout} contentScreen={'AUDIT'} popoutData={popoutData} />
      )}
    </>
  )
}

export default AuditLogs
