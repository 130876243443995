import { forwardRef } from 'react'
import './input.css'

export interface IInputProps {
  value?: string
  placeholder?: string
  onChange?: (value: string) => void
  disabled?: boolean
  type?: string
  error?: boolean
  maxLength?: number
  hasButton?: boolean
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      value: initialValue,
      placeholder,
      onChange,
      disabled = false,
      type = 'text',
      error = false,
      maxLength = 2000,
      hasButton = false,
      ...props
    },
    ref
  ) => {
    return (
      <input
        value={initialValue}
        type={type}
        placeholder={placeholder}
        onChange={(e) => onChange?.(e.target.value)}
        disabled={disabled}
        className={`${disabled ? 'disabled' : ''} ${error ? 'error' : ''} ${hasButton ? 'pr-10' : ''}`}
        maxLength={maxLength}
        ref={ref}
        {...props}
      />
    )
  }
)