interface IIntegrationRemoveModalProps {
  name: string
  setShowModal: (show: boolean) => void
  handleRemoveIntegration: () => void
}

function IntegrationRemoveModal(props: IIntegrationRemoveModalProps) {
  const { name, setShowModal, handleRemoveIntegration } = props

  return (
    <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50" onClick={() => setShowModal(false)}>
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] w-[30%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="block">
          <p className="text-2xl font-semibold text-center">Deactivate Integration</p>
          <p className="mt-4 text-center">
            Are you sure you want to deactivate {name}? You will need to reconfigure in the future.
          </p>
        </div>

        <div className="flex justify-end mt-6">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
            onClick={() => {
              setShowModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-FieryGarnet text-white rounded-lg"
            onClick={() => {
              handleRemoveIntegration()
            }}
          >
            Deactivate
          </button>
        </div>
      </div>
    </div>
  )
}

export default IntegrationRemoveModal
