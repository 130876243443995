import { Cross2Icon } from '@radix-ui/react-icons'
import { Button } from '@radix-ui/themes'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { useState } from 'react'
import { BlocklistDomain } from '../../types/BlocklistTypes'
import UnblockDomainModal from './UnblockDomainModal'
import { useSelector } from 'react-redux'
import { selectUser } from '../../reducers/users'
import { getRoles, permitsRole } from '../../utils/roles'
import { RoleType } from '../../types/UserTypes'

interface IDomainBlocklistPopoutContentProps {
  popoutData: BlocklistDomain
  setShowPopout: (show: boolean) => void
}

export const DomainBlocklistPopoutContent = (props: IDomainBlocklistPopoutContentProps) => {
  const { popoutData, setShowPopout } = props
  const [showUnblockModal, setShowUnblockModal] = useState(false)
  const { session } = useSelector(selectUser)
  const roles = getRoles(session)

  return (
    <>
      {/* Name */}
      <div className="w-full">
        <div className="w-full h-20 border-b flex px-8">
          <div className="w-3/4 h-20 flex flex-col justify-center">
            <div className="text-black text-lg font-semibold leading-normal whitespace-normal max-two-lines">
              {popoutData.name}
            </div>
          </div>
          <div className="w-1/4 h-20 flex justify-end items-center float-right">
            <div
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setShowPopout(false)
              }}
            >
              <Cross2Icon className="w-6 h-6" />
            </div>
          </div>
        </div>
        {/* Domain */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Domain</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="max-w-full truncate text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.domain}
            </div>
          </div>
        </div>
        {/* Added */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Date Added</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {convertTimestampToIverifyDate(popoutData.createdAt, true)}
            </div>
          </div>
        </div>
        {/* Added By */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Added By</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.createdBy}
            </div>
          </div>
        </div>
        {roles && permitsRole(roles, [RoleType.ACCESS_MGMT]) && (
          <div
            onClick={() => setShowUnblockModal(true)}
            className="h-16 w-[399px] fixed bottom-0 right-0 bg-white border-t-[1px] flex items-center"
          >
            <Button className="h-[38px] bg-PurpleBlue-10 ml-8 cursor-pointer ml-auto mr-8">Unblock Domain</Button>
          </div>
        )}
      </div>
      {showUnblockModal && (
        <UnblockDomainModal
          setShowModal={setShowUnblockModal}
          domain={popoutData.domain}
          domainId={popoutData.id}
          setShowPopout={setShowPopout}
        />
      )}
    </>
  )
}
