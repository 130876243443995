import { useState } from 'react'
import { Button } from '@radix-ui/themes'
import { IconCircleMinus, IconFileDownload } from '@tabler/icons-react'
import DropZone from '../drop-zone/DropZone'
import { useSelector } from 'react-redux'
import { selectBlocklist } from '../../reducers/blocklist'
import Papa from 'papaparse'
import toast from 'react-hot-toast'
import { AddDomainInputs } from '../../types/BlocklistTypes'
import { addDomainSchema } from '../../schemas/SettingsSchema'

interface IAddMulipleDomainModalProps {
  setShowModal: (show: boolean) => void
  setCsvDomainsToBlock: (domains: AddDomainInputs[]) => void
}

function AddMulipleDomainModal(props: IAddMulipleDomainModalProps) {
  const { setShowModal, setCsvDomainsToBlock } = props
  const { blocklist } = useSelector(selectBlocklist)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  const TemplateLink = () => (
    <a
      className="flex justify-center text-PurpleBlue-10 font-semibold"
      href="data:text/csv;charset=UTF-8,domainName%2CdomainURL%0AExample%20Name%2Cyour-blocked-organization.com"
      download="domain_template.csv"
    >
      <button className="flex px-4 py-2 mr-2 bg-gray-300 text-neutral-11 rounded">
        <IconFileDownload size={24} className="mr-2" /> <span className="font-normal">Download CSV Template</span>
      </button>
    </a>
  )

  const parseMembersFromCsv = () => {
    Papa.parse(uploadedFiles[0], {
      header: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      complete: async function (results: any) {
        const {
          data,
          meta: { fields },
        } = results

        if (!fields.includes('domainName') || !fields.includes('domainURL')) {
          toast.error('CSV file must have domainName and domainURL headers', { duration: 6000 })
          return
        }

        const seenDomains = new Set()
        const filtered = data
          .filter(
            ({ domainName, domainURL }: { domainName: string; domainURL: string }) =>
              domainName && domainURL && domainName.trim() !== '' && domainURL.trim() !== ''
          )
          .map(({ domainName, domainURL }: { domainName: string; domainURL: string }) => ({
            name: domainName,
            domain: domainURL,
          }))
          .filter(({ domain }: AddDomainInputs) => {
            if (seenDomains.has(domain)) {
              return false
            } else {
              seenDomains.add(domain)
              return true
            }
          })

        const uniqueDomains = filtered.filter(
          (filteredDomain: AddDomainInputs) =>
            !blocklist?.domains?.some((blocked) => blocked.domain === filteredDomain.domain)
        )

        const validDomains = await validateDomains(uniqueDomains)
        if (validDomains.length === 0) {
          toast.error('Found no new domains in the uploaded CSV', { duration: 6000 })
          return
        }
        setCsvDomainsToBlock(validDomains)
      },
    })

    const validateDomains = async (domains: AddDomainInputs[]) => {
      const validDomains: AddDomainInputs[] = []
      for (const domain of domains) {
        try {
          await addDomainSchema.validate(domain)
          validDomains.push(domain)
        } catch (error) {
          toast.error(`Invalid domain format for ${domain.domain}`, { duration: 6000 })
        }
      }
      return validDomains
    }
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowModal(false)}
    >
      <div
        className="fixed w-[724px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[70%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <>
          <h2 className="text-xl font-bold mb-6">Upload Domains</h2>
          <DropZone
            setUploadedFiles={setUploadedFiles}
            customMessage={'File must include “domainName” and “domainURL” columns'}
          />

          <table className="table-auto w-full my-4 min-h-10">
            <tbody>
              {uploadedFiles.map((file: File) => (
                <tr key={file.name}>
                  <td className="px-4 py-2">{file.name}</td>
                  <td className="py-2 text-right w-6">
                    <IconCircleMinus
                      size={24}
                      color={'#1c2024'}
                      className="cursor-pointer ml-auto"
                      onClick={() => setUploadedFiles([])}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <hr className="mb-6 mt-3 border-t border-DarkNavy/10" />
          <div className="flex justify-between mt-6">
            {TemplateLink()}
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 bg-gray-300 text-neutral-11 rounded"
                onClick={() => {
                  setCsvDomainsToBlock([])
                  setShowModal(false)
                }}
              >
                Cancel
              </button>
              <Button
                className={`h-10 px-4 py-2 bg-PurpleBlue-10 text-white ${uploadedFiles.length === 0 ? 'opacity-50' : 'cursor-pointer'}`}
                onClick={() => {
                  parseMembersFromCsv()
                }}
                disabled={uploadedFiles.length === 0}
              >
                Next
              </Button>
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default AddMulipleDomainModal
