import { Button, IconButton, TextField } from '@radix-ui/themes'
import { addMembers, fetchMembers, selectMembers } from '../../reducers/members'
import { useAppDispatch } from '../../reducers/store'
import { useEffect, useState } from 'react'
import { CrossCircledIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { useSelector } from 'react-redux'
import DataGrid from '../data-grid/DataGrid'
import { addDevicesColumns } from './AddDevicesColumns'
import { Member } from '../../types/MemberTypes'
import toast from 'react-hot-toast'
import { fetchDevices } from '../../reducers/devices'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../strings/api-consts'
import { NOT_ACTIVATED } from '../../strings/dropdown-options'

interface IAddDeviceModalProps {
  setShowAddDeviceModal: (show: boolean) => void
  setPlatformToShow: (platform: string) => void
}

const AddDeviceModal = (props: IAddDeviceModalProps) => {
  const { setShowAddDeviceModal, setPlatformToShow } = props
  const di = useAppDispatch()
  const { members } = useSelector(selectMembers)
  const [nameFilter, setNameFilter] = useState<string>('')
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({})
  const [clickedInputs, setClickedInputs] = useState<Member[]>([])
  const [uniqueMembers, setUniqueMembers] = useState<Member[]>([])

  const searchName = () => {
    di(fetchMembers(undefined, nameFilter, undefined, undefined))
  }

  const clearNameFilter = () => {
    di(fetchMembers(undefined, '', undefined, undefined))
  }

  const handleCheckboxChange = (email: string) => {
    const selectedItem = uniqueMembers!.find((item) => item.email === email)!
    if (clickedInputs.includes(selectedItem)) {
      setClickedInputs(clickedInputs.filter((item) => item.email !== email))
    } else {
      setClickedInputs([...clickedInputs, selectedItem])
    }
  }

  useEffect(() => {
    di(fetchMembers(undefined, '', undefined, undefined))
  }, [])

  useEffect(() => {
    if (members) {
      const uniqueMembers = members.filter(
        (member, index, self) => index === self.findIndex((m) => m.email === member.email)
      )
      setUniqueMembers(uniqueMembers)
    }
  }, [members])

  const enrollNewDevices = async () => {
    const emails = Object.entries(checkedItems)
      .filter(([_, value]) => value)
      .map(([key]) => key)

    const membersWithEmails = uniqueMembers!
      .filter((member) => emails.includes(member.email))
      .map((member) => {
        const id = member.devices[0]?.memberId.toString()
        return { name: member.name, email: member.email, id }
      })

    const uniqueMembersWithEmails = membersWithEmails.filter(
      (member, index, self) => index === self.findIndex((m) => m.id === member.id && m.email === member.email)
    )

    const memberResponse = await di(addMembers(uniqueMembersWithEmails))

    if (memberResponse.error) {
      toast.error(memberResponse.error, { duration: 6000 })
    } else {
      toast.success(`Successfully sent the new access code${membersWithEmails.length > 1 ? 's' : ''}`, {
        duration: 6000,
      })
      di(fetchDevices(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, [], NOT_ACTIVATED, ''))
      setPlatformToShow(NOT_ACTIVATED)
      setShowAddDeviceModal(false)
    }
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowAddDeviceModal(false)}
    >
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 h-[660px] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-bold mb-2">Add New Devices</h2>
        <p className="mb-8">
          Select the users that you want to provison new devices for. An email will be sent to each individual with a
          new enrollment code.
        </p>
        {/*  */}
        <TextField.Root
          value={nameFilter}
          placeholder="Search by name..."
          className="w-[200px] h-[38px] mr-4 mb-4"
          onChange={(e) => {
            setNameFilter(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              searchName()
            }
          }}
        >
          <TextField.Slot></TextField.Slot>
          {nameFilter !== '' && (
            <TextField.Slot>
              <IconButton
                size="1"
                variant="ghost"
                onClick={() => {
                  setNameFilter('')
                  clearNameFilter()
                }}
              >
                <CrossCircledIcon height="14" width="14" />
              </IconButton>
            </TextField.Slot>
          )}
          <TextField.Slot>
            <IconButton size="1" variant="ghost" onClick={() => searchName()} className="cursor-pointer">
              <MagnifyingGlassIcon height="14" width="14" />
            </IconButton>
          </TextField.Slot>
        </TextField.Root>
        {uniqueMembers?.length !== 0 && (
          <DataGrid
            columns={addDevicesColumns(checkedItems, setCheckedItems, handleCheckboxChange)}
            data={uniqueMembers}
            loading={false}
            classNames="max-h-[400px]"
          />
        )}
        {uniqueMembers?.length === 0 && (
          <div className="flex items-center justify-center mt-10">
            <p>Search returned no results</p>
          </div>
        )}
        <div className="flex justify-end mt-4">
          <button className="px-4 py-2 mr-2 bg-gray-300 rounded" onClick={() => setShowAddDeviceModal(false)}>
            Cancel
          </button>
          <Button
            className="h-10 px-4 py-2 bg-PurpleBlue-10 text-white cursor-pointer"
            onClick={() => {
              enrollNewDevices()
            }}
            disabled={!Object.values(checkedItems).some((value) => value)}
          >
            Send Access Code
            {Object.entries(checkedItems)
              .filter(([_, value]) => value)
              .map(([key]) => key).length > 1
              ? 's'
              : ''}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddDeviceModal
