import toast from 'react-hot-toast'
import { useAppDispatch } from '../../reducers/store'
import { deleteBlockedDomain, fetchBlocklist } from '../../reducers/blocklist'

interface IUnblockDomainModalProps {
  setShowModal: (show: boolean) => void
  domain: string
  domainId: number
  setShowPopout: (show: boolean) => void
}

function UnblockDomainModal(props: IUnblockDomainModalProps) {
  const { setShowModal, domain, domainId, setShowPopout } = props
  const di = useAppDispatch()

  const handleUnblockDomain = async () => {
    const memberUnblocked = await di(deleteBlockedDomain(domainId))
    if (!memberUnblocked) {
      toast.error('An error occurred while unblocking the domain', { duration: 6000 })
    } else {
      toast.success(`${domain} has been unblocked`, { duration: 6000 })
    }
    di(fetchBlocklist())
    setShowPopout(false)
    setShowModal(false)
  }

  return (
    <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50" onClick={() => setShowModal(false)}>
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] w-[30%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="block">
          <p className="text-2xl font-semibold text-center">Unblock Domain</p>
          <p className="mt-4 text-center">
            Are you sure you want to unblock {domain}? Members will be able to regain access to this website.
          </p>
        </div>

        <div className="flex justify-end mt-6">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
            onClick={() => {
              setShowModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-FieryGarnet text-white rounded-lg"
            onClick={() => {
              handleUnblockDomain()
            }}
          >
            Yes, Unblock
          </button>
        </div>
      </div>
    </div>
  )
}

export default UnblockDomainModal
