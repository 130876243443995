/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Integration } from '../../types/IntegrationTypes'

export interface IntegrationsState {
  integrationsList: Integration[] | undefined
}

const initialState: IntegrationsState = {
  integrationsList: undefined,
}

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setIntegrationsList(state, action: PayloadAction<any>) {
      state.integrationsList = action.payload
    },
  },
})
export const selectIntegrations = (state: RootState) => state.integrations

export const { setIntegrationsList } = integrationsSlice.actions
export default integrationsSlice.reducer
