import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import AddSSOMembersModal from '../members/AddSSOMembersModal'
import DisconnectModal from './DisconnectModal'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addThreatHunterSchema } from '../../schemas/SettingsSchema'
import { useAppDispatch } from '../../reducers/store'
import { addThreatHunterUser, fetchThreatHunterUsers, selectSettings } from '../../reducers/settings'
import DataGrid from '../data-grid/DataGrid'
import { threatHunterColumns } from './ThreatHunterColumns'
import toast from 'react-hot-toast'
import { Button } from '@radix-ui/themes'
import { Input } from '../input/Input'
import { AddThreatHunterUser } from '../../types/SettingsType'

function ThreatHunterSettings() {
  const di = useAppDispatch()
  const { threatHunterUsers } = useSelector(selectSettings)
  const [showDisconnectModal, setShowDisconnectModal] = useState<boolean>(false)
  const [showSSOModal, setShowSSOModal] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddThreatHunterUser>({ resolver: yupResolver(addThreatHunterSchema) })

  const onSubmit: SubmitHandler<AddThreatHunterUser> = async (adminDetails) => {
    const sent = await di(addThreatHunterUser(adminDetails))
    if (!sent) {
      toast.error('Unable to provision Threat Hunter access, please try again.', { duration: 6000 })
    } else {
      toast.success(`An invite has been sent to ${adminDetails.name} at ${adminDetails.email}`, { duration: 6000 })
      reset()
      di(fetchThreatHunterUsers())
    }
  }

  useEffect(() => {
    fetchThreatHunterUsersData()
  }, [])

  const fetchThreatHunterUsersData = async () => {
    const users = await di(fetchThreatHunterUsers())
    if (users && users.error) {
      toast.error(users.error, { duration: 6000 })
    }
  }

  return (
    <>
      <div className="flex flex-col h-full w-full">
        <form onSubmit={handleSubmit(onSubmit)} className="block mt-4">
          <div className="flex">
            <div className="mr-2 w-[180px]">
              <label className="text-darkGreyBlue font-semibold text-xs block">FULL NAME</label>
              <Input
                type="text"
                placeholder="Full Name"
                {...register('name', { required: true })}
                onChange={undefined}
              />
              {errors.name && <p className="text-ErrorRed">{errors.name.message}</p>}
            </div>
            <div className="w-[272px]">
              <label className="text-darkGreyBlue font-semibold text-xs block">EMAIL</label>
              <Input
                type="email"
                placeholder="name@example.com"
                {...register('email', { required: true })}
                onChange={undefined}
              />
              {errors.email && <p className="text-ErrorRed">{errors.email.message}</p>}
            </div>
            <Button type="submit" className="w-20 h-[38px] bg-PurpleBlue-10 rounded mt-4 ml-4 p-2.5 cursor-pointer">
              Add
            </Button>
          </div>
        </form>
        <p className="font-semibold text-lg mt-5">Provisioned Users</p>
        {(!threatHunterUsers || threatHunterUsers.length === 0) && (
          <p>No users found. Use the Provision Threat Hunter form to invite users</p>
        )}
        {threatHunterUsers && threatHunterUsers.length > 0 && (
          <DataGrid columns={threatHunterColumns()} data={threatHunterUsers} classNames="mb-8" loading={false} />
        )}
      </div>
      {showDisconnectModal && <DisconnectModal setShowModal={setShowDisconnectModal} screen={'SSO'} />}
      {showSSOModal && <AddSSOMembersModal setShowModal={setShowSSOModal} goToGroups={false} />}
    </>
  )
}

export default ThreatHunterSettings
