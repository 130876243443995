import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import DataGrid from '../data-grid/DataGrid'
import { useSelector } from 'react-redux'
import Popout from '../popout/Popout'
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Popover,
  SegmentedControl,
  Strong,
  Text,
  TextField,
} from '@radix-ui/themes'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import checkIcon from '../../assets/check_circle.svg'
import { selectBlocklist } from '../../reducers/blocklist/blocklist.slice.ts'
import { fetchBlocklist, fetchBlocklistLogs } from '../../reducers/blocklist/blocklist.thunk.ts'
import { domainBlocklistColumns } from './DomainBlocklistColumns.tsx'
import AddDomainModal from './AddDomainModal.tsx'
import { AddDomainInputs, BlocklistDomain, BlocklistLog } from '../../types/BlocklistTypes.ts'
import PageSkeleton from '../skeleton/PageSkeleton.tsx'
import { domainBlocklistLogsColumns } from './DomainBlocklistLogsColumns.tsx'
import { IconForms, IconUpload } from '@tabler/icons-react'
import AddMulipleDomainModal from './AddMulipleDomainModal.tsx'
import { getRoles, permitsRole } from '../../utils/roles.ts'
import { selectUser } from '../../reducers/users/users.slice.ts'
import { RoleType } from '../../types/UserTypes.ts'

function DomainBlocklistWrapper() {
  const { blocklist, blocklistLogs, noDomains, noLogs } = useSelector(selectBlocklist)
  const di = useAppDispatch()
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [nameFilter, setNameFilter] = useState<string>('')
  const [showPopout, setShowPopout] = useState<boolean>(false)
  const [popoutData, setPopoutData] = useState<BlocklistDomain | BlocklistLog | null>(null)
  const [showManualAddDomainModal, setShowManualAddDomainModal] = useState<boolean>(false)
  const [showMultiAddDomainModal, setShowMultiAddDomainModal] = useState<boolean>(false)
  const [screenToShow, setScreenToShow] = useState<string>('domains')
  const [openDomainOptions, setOpenDomainOptions] = useState<boolean>(false)
  const [csvDomainsToBlock, setCsvDomainsToBlock] = useState<AddDomainInputs[]>([])

  const { session } = useSelector(selectUser)
  const roles = getRoles(session)

  useEffect(() => {
    setDataLoading(true)
    di(fetchBlocklist())
    di(fetchBlocklistLogs())
  }, [])

  useEffect(() => {
    if (blocklist || noDomains) {
      setDataLoading(false)
    } else if (!blocklist) {
      setDataLoading(true)
    }
  }, [blocklist, noDomains, blocklistLogs, noLogs])

  useEffect(() => {
    if (csvDomainsToBlock.length > 0) {
      setShowMultiAddDomainModal(false)
      setShowManualAddDomainModal(true)
    }
  }, [csvDomainsToBlock])

  const searchName = () => {
    setDataLoading(true)
    if (screenToShow === 'domains') {
      di(fetchBlocklist(nameFilter))
    } else {
      di(fetchBlocklistLogs(nameFilter))
    }
  }

  const clearNameFilter = () => {
    setDataLoading(true)
    di(fetchBlocklist())
    di(fetchBlocklistLogs())
  }

  useEffect(() => {
    if (nameFilter !== '') {
      setNameFilter('')
      clearNameFilter()
    }
  }, [screenToShow])

  return (
    <>
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] bg-neutral-3 p-9">
        {dataLoading && (
          <div className="flex justify-center items-center min-h-screen">
            <PageSkeleton />
          </div>
        )}
        {!dataLoading && (
          <div className="flex justify-between">
            <div className="mb-6 flex w-full">
              <SegmentedControl.Root
                defaultValue="domains"
                value={screenToShow}
                className="h-[38px] mr-4 bg-[#00003B0D] text-gray-600"
                onValueChange={(newValue: string) => setScreenToShow(newValue)}
              >
                <SegmentedControl.Item value="domains">Domains</SegmentedControl.Item>
                <SegmentedControl.Item value="requests">Requests</SegmentedControl.Item>
              </SegmentedControl.Root>
              <TextField.Root
                value={nameFilter}
                placeholder="Search by name..."
                className="w-[240px] h-[38px] mr-4"
                onChange={(e) => {
                  setNameFilter(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchName()
                  }
                }}
              >
                <TextField.Slot></TextField.Slot>
                {nameFilter !== '' && (
                  <TextField.Slot>
                    <IconButton
                      size="1"
                      variant="ghost"
                      onClick={() => {
                        setNameFilter('')
                        clearNameFilter()
                      }}
                    >
                      <CrossCircledIcon height="14" width="14" />
                    </IconButton>
                  </TextField.Slot>
                )}
                <TextField.Slot>
                  <IconButton size="1" variant="ghost" onClick={() => searchName()}>
                    <MagnifyingGlassIcon height="14" width="14" />
                  </IconButton>
                </TextField.Slot>
              </TextField.Root>
            </div>
            {roles && permitsRole(roles, [RoleType.ACCESS_MGMT]) && (
              <div className="flex ml-auto justify-end">
                <Popover.Root open={openDomainOptions} onOpenChange={setOpenDomainOptions}>
                  <Popover.Trigger>
                    <Button className="h-[38px] bg-PurpleBlue-10 ml-8 cursor-pointer">
                      <span className="hidden xl:flex">Add Domain</span>
                    </Button>
                  </Popover.Trigger>
                  <Popover.Content width="360px" className="flex justify-content bg-neutral-2 mr-6">
                    <Grid columns="2" gapX={'10px'}>
                      <Box
                        maxWidth="160px"
                        className="cursor-pointer"
                        onClick={() => {
                          setOpenDomainOptions(false)
                          setShowMultiAddDomainModal(true)
                        }}
                      >
                        <Card size="2">
                          <div
                            className="h-8 w-8 rounded flex justify-center items-center"
                            style={{ backgroundColor: '#F2F0FF' }}
                          >
                            <IconUpload size={20} color="#6128D1" />
                          </div>
                          <Text as="p" size="1" className="mt-5">
                            <Strong>Upload</Strong>
                            <br /> Upload a populated CSV file for bulk import of domains.
                          </Text>
                        </Card>
                      </Box>
                      <Box
                        maxWidth="160px"
                        className="cursor-pointer"
                        onClick={() => {
                          setOpenDomainOptions(false)
                          setShowManualAddDomainModal(true)
                        }}
                      >
                        <Card size="2" className="min-h-[148px]">
                          <div
                            className="h-8 w-8 rounded flex justify-center items-center"
                            style={{ backgroundColor: '#F2F0FF' }}
                          >
                            <IconForms size={20} color="#6128D1" />
                          </div>
                          <Text as="p" size="1" className="mt-5">
                            <Strong>Manual</Strong>
                            <br /> Manually add multiple domains.
                          </Text>
                        </Card>
                      </Box>
                    </Grid>
                  </Popover.Content>
                </Popover.Root>
              </div>
            )}
          </div>
        )}

        {screenToShow === 'domains' && blocklist && blocklist.domains?.length > 0 && (
          <>
            <DataGrid
              columns={domainBlocklistColumns(setShowPopout, setPopoutData)}
              data={blocklist.domains}
              loading={dataLoading}
            />
          </>
        )}
        {screenToShow === 'requests' && blocklistLogs && blocklistLogs?.length > 0 && (
          <>
            <DataGrid
              columns={domainBlocklistLogsColumns(setShowPopout, setPopoutData)}
              data={blocklistLogs}
              loading={dataLoading}
            />
          </>
        )}
        {screenToShow === 'domains' && (noDomains || (blocklist && blocklist.domains?.length === 0)) && (
          <div className="block items-center mt-20">
            <div className="flex items-center justify-center">
              <img src={checkIcon} alt="No members found" className="h-12 w-12" />
            </div>
            <p className="text-center font-bold">No Domains Found</p>
            <p className="text-center text-sm">Add blocked domains above or refine your search</p>
          </div>
        )}
        {screenToShow === 'requests' && (noLogs || (blocklistLogs && blocklistLogs?.length === 0)) && (
          <div className="block items-center mt-20">
            <div className="flex items-center justify-center">
              <img src={checkIcon} alt="No members found" className="h-12 w-12" />
            </div>
            <p className="text-center font-bold">No Request Logs Found</p>
            <p className="text-center text-sm">Add blocked domains above or refine your search</p>
          </div>
        )}
      </div>
      {showManualAddDomainModal && (
        <AddDomainModal
          setShowModal={setShowManualAddDomainModal}
          csvDomainsToBlock={csvDomainsToBlock}
          setCsvDomainsToBlock={setCsvDomainsToBlock}
        />
      )}
      {showMultiAddDomainModal && (
        <AddMulipleDomainModal setShowModal={setShowMultiAddDomainModal} setCsvDomainsToBlock={setCsvDomainsToBlock} />
      )}
      {showPopout && (
        <Popout
          setShowPopout={setShowPopout}
          contentScreen={screenToShow === 'domains' ? 'DOMAINS' : 'REQUESTS'}
          popoutData={popoutData}
        />
      )}
    </>
  )
}

export default DomainBlocklistWrapper
