import { SelectOption } from '../types/DropDownTypes'
import { GracePeriod } from '../types/MemberTypes'
import { RoleType } from '../types/UserTypes'

export const INVITATION = 'invitation'
export const NOT_ACTIVATED = 'not_activated'

export const severityOptions: SelectOption[] = [
  { value: 'critical', label: 'Critical', color: 'rgba(187, 0, 7, 0.84)', bgColor: 'rgba(255, 239, 239, 1)' },
  {
    value: 'warning',
    label: 'Fixes Required',
    color: 'rgba(120, 50, 0, 0.81)',
    bgColor: 'rgba(255, 191, 1, 0.19)',
  },
  { value: 'secure', label: 'Secure', color: 'rgba(0, 107, 59, 0.91)', bgColor: 'rgba(2, 186, 60, 0.09)' },
]

export const actionOptions: SelectOption[] = [
  { value: INVITATION, label: 'Send Invite' },
  { value: NOT_ACTIVATED, label: 'Remove Device' },
]

export const alertOptions: SelectOption[] = [
  { value: 'open', label: 'Open', color: 'rgba(0, 0, 171, 0.72)', bgColor: 'rgba(5, 5, 255, 0.05)' },
  { value: 'closed', label: 'Closed', color: 'rgba(187, 0, 7, 0.84)', bgColor: 'rgba(255, 239, 239, 1)' },
]

export const activatedOptions: SelectOption[] = [
  { value: 'yes', label: 'Yes', color: 'rgba(0, 107, 59, 0.91)', bgColor: 'rgba(2, 186, 60, 0.09)' },
  { value: 'no', label: 'No', color: 'rgba(187, 0, 7, 0.84)', bgColor: 'rgba(255, 239, 239, 1)' },
]

export const rolesOptions: SelectOption[] = [
  {
    value: 'SUPER_ADMIN',
    label: RoleType.SUPER_ADMIN,
    description: 'Full access to all features and functions',
    color: 'var(--accent-9)',
    bgColor: 'var(--accent-2)',
    order: 0,
  },
  {
    value: 'PLATFORM_MGMT',
    label: RoleType.PLATFORM_MGMT,
    description: 'Access platform settings only',
    color: 'var(--neutral-12)',
    bgColor: 'var(--accent-2)',
    order: 1,
  },
  {
    value: 'ACCESS_MGMT',
    label: RoleType.ACCESS_MGMT,
    description: 'Reinstate users suspended by conditional access',
    color: 'var(--neutral-12)',
    bgColor: 'var(--accent-2)',
    order: 2,
  },
  {
    value: 'GENERAL_ADMIN',
    label: RoleType.GENERAL_ADMIN,
    description: 'Add users, devices and alerts',
    color: 'var(--neutral-12)',
    bgColor: 'var(--accent-2)',
    order: 3,
  },
]

export const enrollOptions = [
  { value: 'ONCE', label: 'Once' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'EVERY_SECOND_DAY', label: 'Every Second Day' },
  { value: 'NEVER', label: 'Never' },
]
export const notificationAlertOptions = [
  { value: 'ENABLED', label: 'Enabled' },
  { value: 'DISABLED', label: 'Disabled' },
]
export const outdatedOptions = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'EVERY_SECOND_DAY', label: 'Every Second Day' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'NEVER', label: 'Never' },
]
export const scanOptions = [
  { value: 'THREE_DAYS', label: '3 Days' },
  { value: 'SEVEN_DAYS', label: '7 Days' },
  { value: 'TWO_WEEKS', label: '2 Weeks' },
  { value: 'NEVER', label: 'Never' },
]
export const pushOptions = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'EVERY_SECOND_DAY', label: 'Every Second Day' },
  { value: 'WEEKLY', label: 'Weekly' },
]
export const deliveryOptions = [
  { value: 'PUSH', label: 'Push' },
  { value: 'EMAIL', label: 'Email' },
]

export const gracePeriodOptions: SelectOption[] = [
  { value: GracePeriod.ONE_HOUR, label: '1 Hour' },
  { value: GracePeriod.FOUR_HOURS, label: '4 Hours' },
  { value: GracePeriod.ONE_DAY, label: '1 Day' },
  { value: GracePeriod.THREE_DAYS, label: '3 Days' },
  { value: GracePeriod.ONE_WEEK, label: '1 Week' },
]
