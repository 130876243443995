import { IconLockFilled } from '@tabler/icons-react'
import Header from '../header/Header'
import { Button, Text } from '@radix-ui/themes'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { setCurrentPage } from '../../reducers/users'
import { useDispatch } from 'react-redux'

function Forbidden() {
  const dispatch = useDispatch()
  useEffect(() => {
    localStorage.removeItem('SETTINGS_PAGE_COOKIE')
    dispatch(setCurrentPage('DASHBOARD'))
  }, [])
  const navigate = useNavigate()
  return (
    <div className="light h-screen flex">
      <Header />
      <div className="flex flex-col items-center justify-center w-full gap-4 ">
        <IconLockFilled className="m-2" size={64} />
        <Text size="7" weight="medium">
          Permission Required
        </Text>
        <p className="text-lg text-center max-w-[430px]">
          It looks like your account doesn’t have permission to access this page. For assistance, please reach out to a
          Super Admin to grant the required role.
        </p>
        <Button
          className="max-w-[200px] w-full rounded cursor-pointer bg-PurpleBlue-9 hover:bg-PurpleBlue-10 active:bg-PurpleBlue-10"
          onClick={() => navigate('/')}
        >
          Return to Portal
        </Button>
      </div>
    </div>
  )
}

export default Forbidden
