import toast from 'react-hot-toast'
import { useAppDispatch } from '../../reducers/store'
import { fetchMembers, postRemoveMember } from '../../reducers/members'

interface IUnblockMemberModalProps {
  setShowModal: (show: boolean) => void
  memberName: string
  memberId: string
  setShowPopout: (show: boolean) => void
}

function RemoveMemberModal(props: IUnblockMemberModalProps) {
  const { setShowModal, memberName, memberId, setShowPopout } = props
  const di = useAppDispatch()

  const handleUnblockMember = async () => {
    const memberUnblocked = await di(postRemoveMember(memberId))
    if (!memberUnblocked) {
      toast.error('An error occurred while removing the member', { duration: 6000 })
    } else {
      toast.success(`${memberName} has been removed.`, { duration: 6000 })
    }
    di(fetchMembers())
    setShowPopout(false)
    setShowModal(false)
  }

  return (
    <div className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50" onClick={() => setShowModal(false)}>
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] w-[30%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="block">
          <p className="text-2xl font-semibold">Remove Member</p>
          <p className="mt-4">
            Are you sure you want to remove {memberName}? Any associated devices will be removed and you will need to
            re-add the member in the future.
          </p>
        </div>

        <div className="flex justify-end mt-6">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
            onClick={() => {
              setShowModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-FieryGarnet text-white rounded-lg"
            onClick={() => {
              handleUnblockMember()
            }}
          >
            Remove Member
          </button>
        </div>
      </div>
    </div>
  )
}

export default RemoveMemberModal
