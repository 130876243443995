import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import { CrossCircledIcon, PlusIcon } from '@radix-ui/react-icons'
import toast from 'react-hot-toast'
import { addMemberInputs, MemberPayload } from '../../types/MemberTypes'
import { useAppDispatch } from '../../reducers/store'
import { addMembers, fetchMembers } from '../../reducers/members/members.thunk'
import { Button } from '@radix-ui/themes'
import { Input } from '../input/Input'
import { addMemberSchema } from '../../schemas/MembersSchema'

interface IAddMembersModalProps {
  setShowModal: (show: boolean) => void
}

function AddMembersModal(props: IAddMembersModalProps) {
  const { setShowModal } = props
  const [membersToAdd, setMembersToAdd] = useState<addMemberInputs[]>([])
  const [loading, setLoading] = useState(false)
  const di = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<addMemberInputs>({ resolver: yupResolver(addMemberSchema) })

  const onSubmit: SubmitHandler<addMemberInputs> = async (member) => {
    if (!membersToAdd.some((member) => member.email.toLowerCase() === member.email.toLowerCase())) {
      setMembersToAdd([...membersToAdd, member])
      reset()
    } else {
      toast.error('Member already added', { duration: 6000 })
    }
  }

  const addMembersFromForm = async (members: MemberPayload[]) => {
    setLoading(true)
    members.forEach((member, index) => {
      member.id = (index + 1).toString()
    })
    const newMembers = await di(addMembers(members))
    setLoading(false)
    if (newMembers.error) {
      toast.error(newMembers.error, { duration: 6000 })
    } else {
      di(fetchMembers(undefined, '', undefined, undefined))
      toast.success(`Successfully added ${members.length} new member${members.length === 1 ? '' : 's'}`, {
        duration: 6000,
      })
      setShowModal(false)
    }
  }

  const removeMember = (email: string) => {
    const newMembers = membersToAdd.filter((member) => member.email !== email)
    setMembersToAdd(newMembers)
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowModal(false)}
    >
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[70%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-bold mb-4">Add Team Members</h2>
        <p className="mb-2">
          An email will be sent to each individual with a unique code to enroll their device in your organization.
          <br />
          {membersToAdd.length > 0 && (
            <i>
              {membersToAdd.length} code will be generated and billing will be updated to reflect the new device count.
            </i>
          )}
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="block mt-4">
          <div className="flex">
            <div className="mr-2 w-[180px]">
              <label className="text-darkGreyBlue font-semibold text-xs block">FULL NAME</label>
              <Input
                type="text"
                placeholder="Full Name"
                {...register('name', { required: true })}
                onChange={undefined}
                maxLength={50}
              />
              {errors.name && <p className="text-ErrorRed">{errors.name.message}</p>}
            </div>
            <div className="w-[272px]">
              <label className="text-darkGreyBlue font-semibold text-xs block">EMAIL</label>
              <Input
                type="email"
                placeholder="name@example.com"
                {...register('email', { required: true })}
                onChange={undefined}
              />
              {errors.email && <p className="text-ErrorRed">{errors.email.message}</p>}
            </div>
            <Button
              type="submit"
              className="w-[36px] h-[36px] bg-PurpleBlue-10 rounded-full mt-[19px] ml-4 p-2.5 text-white font-semibold text-sm cursor-pointer"
              value="Add"
            >
              <PlusIcon />
            </Button>
          </div>
        </form>

        <ul className="list-disc pl-6 py-2 my-4 bg-tableHeaderGrey rounded-xl min-h-[140px]">
          {membersToAdd.map((item: addMemberInputs) => (
            <li key={item.email}>
              <div className="flex justify-between">
                {item.name} - {item.email}
                <CrossCircledIcon
                  height={20}
                  width={20}
                  className="text-ErrorRed mr-2 cursor-pointer"
                  onClick={() => removeMember(item.email)}
                />
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-end">
          <button className="px-4 py-2 mr-2 bg-gray-300 rounded" onClick={() => setShowModal(false)}>
            Cancel
          </button>
          <Button
            className={`h-10 px-4 py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ' cursor-pointer'} bg-PurpleBlue-10 text-white`}
            onClick={() => {
              addMembersFromForm(membersToAdd)
            }}
            disabled={membersToAdd.length === 0 || loading}
          >
            {loading ? 'Inviting...' : 'Send Invitations'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddMembersModal
