import { useEffect, useState } from 'react'
import DataGrid from '../data-grid/DataGrid.tsx'
import { useAppDispatch } from '../../reducers/store.ts'
import { useDispatch, useSelector } from 'react-redux'
import DropDown from '../dropdown/DropDown.tsx'
import { alertOptions } from '../../strings/dropdown-options.ts'
import { SelectOption } from '../../types/DropDownTypes.ts'
import { Alert } from '../../types/AlertTypes.ts'
import { closeAlert, fetchAlerts, generateAndDownloadReport } from '../../reducers/alerts/alerts.thunk.ts'
import { selectAlerts, setAlerts } from '../../reducers/alerts/alerts.slice.ts'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../strings/api-consts.ts'
import Popout from '../popout/Popout.tsx'
import { alertColumns } from './AlertColumns.tsx'
import { useNavigate } from 'react-router-dom'
import AlertsResolveModal from './AlertsResolveModal.tsx'
import toast from 'react-hot-toast'
import { MemberSelectOption } from '../../types/MemberTypes.ts'
import checkIcon from '../../assets/check_circle.svg'
import { fetchThreatHunterUsers } from '../../reducers/settings/settings.thunk.ts'
import { ThreatHunterUsersResponse } from '../../types/SettingsType.ts'
import PageSkeleton from '../skeleton/PageSkeleton.tsx'
import { Pagination } from '../pagination/Pagination.tsx'

function AlertsWrapper() {
  const di = useAppDispatch()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { alerts, totalPages, currentPage } = useSelector(selectAlerts)

  const [statusFilter, setStatusFilter] = useState<SelectOption[]>([alertOptions[0]])
  const [showPopout, setShowPopout] = useState<boolean>(false)
  const [popoutData, setPopoutData] = useState<Alert | null>(null)
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [showResolveModal, setShowResolveModal] = useState<boolean>(false)
  const [threatHunterUsers, setThreatHunterUsers] = useState<ThreatHunterUsersResponse | { error: string }>({
    threatHunterUsers: [],
  })
  const defaultSortOrder = 'desc'
  const defaultSortColumn = 'updatedAt'
  const [sortOrder, setSortOrder] = useState<string>(defaultSortOrder)
  const [sortColumn, setSortColumn] = useState<string>(defaultSortColumn)

  useEffect(() => {
    setDataLoading(true)
    if (threatHunterUsers) {
      di(fetchAlerts(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, statusFilter, threatHunterUsers, sortOrder, sortColumn))
    }
  }, [statusFilter, threatHunterUsers, sortColumn, sortOrder])

  useEffect(() => {
    if (alerts || (alerts && (alerts as Alert[]).length === 0)) {
      setDataLoading(false)
    } else if (!alerts) {
      setDataLoading(true)
    }
  }, [alerts])

  useEffect(() => {
    const session = localStorage.getItem('session') as string
    if (session === '{}') {
      navigate('/login')
    }
    fetchThreatHunterUsersData()
    return () => {
      dispatch(setAlerts(undefined))
    }
  }, [])

  const sortBy = (column: string) => {
    if (sortColumn === column) {
      if (sortOrder === 'desc' && column !== defaultSortColumn) {
        // Clear Sort
        setSortColumn(defaultSortColumn)
        setSortOrder(defaultSortOrder)
      } else {
        // Sort Existing Column
        setSortOrder(sortOrder == 'desc' ? 'asc' : 'desc')
      }
    } else {
      // Sort New Column
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const fetchThreatHunterUsersData = async () => {
    const users = await di(fetchThreatHunterUsers())
    setThreatHunterUsers(users)
  }

  const handleCloseAlert = async (alertId: number, scanId: string) => {
    const closed = await di(closeAlert(alertId, scanId))
    if (closed) {
      setShowPopout(false)
      toast.success('Alert has been successfully resolved', { duration: 6000 })
      await di(fetchAlerts(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, statusFilter, threatHunterUsers, sortOrder, sortColumn))
    } else {
      toast.error('There was an issue resolving the alert', { duration: 6000 })
    }
  }

  const downloadAlertReport = async (scanId: string, isThreatHunter: boolean) => {
    await di(generateAndDownloadReport(scanId, isThreatHunter))
  }

  const handlePageClick = (data: MemberSelectOption) => {
    setDataLoading(true)
    di(fetchAlerts(data.selected + 1, DEFAULT_PAGE_SIZE, statusFilter, threatHunterUsers, sortOrder, sortColumn))
  }

  return (
    <>
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l bg-neutral-3 w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] p-9">
        {!dataLoading && (
          <div className="mb-6 flex w-full">
            <DropDown
              value={statusFilter}
              onChangeHandler={setStatusFilter}
              options={alertOptions}
              style="z-10 h-8 mr-4 min-w-[160px]"
              placeholder="Alert Status"
              multiple={true}
              disabled={false}
            />
          </div>
        )}

        {!alerts && (
          <div className="flex justify-center items-center min-h-screen">
            <PageSkeleton />
          </div>
        )}
        {alerts && alerts.length > 0 && (
          <>
            <DataGrid
              columns={alertColumns(setShowPopout, setPopoutData, downloadAlertReport)}
              sortFunction={(column: string) => {
                sortBy(column)
              }}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              data={alerts}
              loading={dataLoading}
            />
            <div className="mt-2">
              <Pagination totalPages={totalPages} forcePage={currentPage} handlePageClick={handlePageClick} />
            </div>
          </>
        )}
        {alerts && alerts.length === 0 && (
          <div className="block items-center mt-20">
            <div className="flex items-center justify-center">
              <img src={checkIcon} alt="No open alerts found" className="h-12 w-12" />
            </div>{' '}
            <p className="text-center font-bold">No Open Alerts</p>
            <p className="text-center text-sm">You have no actions for now</p>
          </div>
        )}
      </div>
      {showPopout && (
        <Popout
          setShowPopout={setShowPopout}
          contentScreen="ALERT"
          popoutData={popoutData}
          setShowResolveModal={setShowResolveModal}
        />
      )}
      {showResolveModal && (
        <AlertsResolveModal
          setShowResolveModal={setShowResolveModal}
          resolveAlert={handleCloseAlert}
          alertId={popoutData!.id}
          scanId={popoutData!.scanId}
        />
      )}
    </>
  )
}

export default AlertsWrapper
