import * as Sentry from '@sentry/browser'
import { API_URL } from '../strings/api-consts'
import { CustomThunkDispatch } from '../types/UserTypes'
import { setLoginPage, setSession } from '../reducers/users'

class Server {
  private headers: HeadersInit
  private credentials: RequestCredentials = 'include'

  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(error: any) {
    try {
      Sentry.captureMessage(error.message)
    } catch (error) {
      console.error(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get(endpoint: string, dispatch: CustomThunkDispatch): Promise<any> {
    try {
      return await fetch(API_URL + endpoint, {
        headers: this.headers,
        credentials: this.credentials,
      }).then(async (response) => {
        if (response.status === 403) {
          window.location.href = '/forbidden'
        }
        if (response.status === 401) {
          localStorage.clear()
          dispatch(setLoginPage('LOGIN'))
          dispatch(setSession(undefined))
          window.location.href = '/login'
        }
        if (response.status === 404) {
          return null
        }

        return await response.json()
      })
    } catch (error) {
      console.error(error)
      this.handleError(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async post(endpoint: string, body: any, dispatch: CustomThunkDispatch) {
    try {
      return await fetch(API_URL + endpoint, {
        method: 'POST',
        headers: this.headers,
        credentials: this.credentials,
        body: JSON.stringify(body),
      }).then(async (response) => {
        if (response.status === 403) {
          window.location.href = '/forbidden'
        }
        if (response.status === 401) {
          localStorage.clear()
          dispatch(setLoginPage('LOGIN'))
          dispatch(setSession(undefined))
          window.location.href = '/login'
        }

        return await response
      })
    } catch (error) {
      this.handleError(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async put(endpoint: string, body: any, dispatch: CustomThunkDispatch) {
    try {
      return await fetch(API_URL + endpoint, {
        method: 'PUT',
        headers: this.headers,
        credentials: this.credentials,
        body: JSON.stringify(body),
      }).then(async (response) => {
        if (response.status === 403) {
          window.location.href = '/forbidden'
        }
        if (response.status === 401) {
          localStorage.clear()
          dispatch(setLoginPage('LOGIN'))
          dispatch(setSession(undefined))
          window.location.href = '/login'
        }

        return await response
      })
    } catch (error) {
      this.handleError(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async delete(endpoint: string, dispatch: CustomThunkDispatch, body?: any) {
    try {
      const request: {
        method: string
        headers: HeadersInit
        credentials: RequestCredentials
        body?: string
      } = {
        method: 'DELETE',
        headers: this.headers,
        credentials: this.credentials,
      }
      if (body) {
        request['body'] = JSON.stringify(body)
      }
      return await fetch(API_URL + endpoint, request).then(async (response) => {
        if (response.status === 403) {
          window.location.href = '/forbidden'
        }
        if (response.status === 401) {
          localStorage.clear()
          dispatch(setLoginPage('LOGIN'))
          dispatch(setSession(undefined))
          window.location.href = '/login'
        }
        return await response
      })
    } catch (error) {
      this.handleError(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async patch(endpoint: string, body: any, dispatch: CustomThunkDispatch) {
    try {
      return await fetch(API_URL + endpoint, {
        method: 'PATCH',
        headers: this.headers,
        credentials: this.credentials,
        body: JSON.stringify(body),
      }).then(async (response) => {
        if (response.status === 403) {
          window.location.href = '/forbidden'
        }
        if (response.status === 401) {
          localStorage.clear()
          dispatch(setLoginPage('LOGIN'))
          dispatch(setSession(undefined))
          window.location.href = '/login'
        }

        return await response
      })
    } catch (error) {
      this.handleError(error)
    }
  }
}

export default Server
