import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import IntegrationCard from './IntegrationCard'
import { useAppDispatch } from '../../reducers/store'
import { fetchIntegrationsList, selectIntegrations } from '../../reducers/integrations'
import { useSelector } from 'react-redux'
import PageSkeleton from '../skeleton/PageSkeleton'
import { IntegrationDetails, PageState } from '../../types/IntegrationTypes'
import IntegrationPopout from './IntegrationPopout'

function IntegrationsWrapper() {
  const navigate = useNavigate()
  const di = useAppDispatch()
  const { integrationsList } = useSelector(selectIntegrations)
  const [showManageIntegrationPopout, setShowManageIntegrationPopout] = useState(false)
  const [popoutIntegration, setPopoutIntegration] = useState<IntegrationDetails | undefined>(undefined)

  useEffect(() => {
    const session = localStorage.getItem('session') as string
    if (session === '{}') {
      navigate('/login')
    }
    getIntegrationList()
  }, [])

  const getIntegrationList = async () => {
    await di(fetchIntegrationsList())
  }

  const manageIntegration = (integration: IntegrationDetails) => {
    setPopoutIntegration(integration)
    setShowManageIntegrationPopout(true)
  }

  return (
    <>
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] bg-neutral-3">
        {!integrationsList && <PageSkeleton />}
        {integrationsList && (
          <div className="max-w-[998px] mx-auto mt-6">
            {integrationsList
              .filter((integration) => integration.pageState === PageState.ACTIVE)
              .flatMap((activeIntegration) => activeIntegration.integrations).length > 0 && (
              <>
                <div className="flex">
                  <svg width="24" height="24" className="mt-0.5">
                    <circle cx="12" cy="12" r="9" className="fill-green-7" />
                    <circle cx="12" cy="12" r="7" className="fill-green-9" />
                  </svg>
                  <p className="text-black text-lg">Active</p>
                </div>
                {integrationsList
                  .filter((integration) => integration.pageState === PageState.ACTIVE)
                  .flatMap((activeIntegration) => activeIntegration.integrations)
                  .map((integration) => (
                    <IntegrationCard
                      key={integration.serverValue}
                      integration={integration}
                      integrationName={integration.name}
                      integrationDescription={integration.description}
                      logoUrl={integration.image}
                      docsUrl={integration.docsLink}
                      manageIntegration={manageIntegration}
                    />
                  ))}
              </>
            )}

            {integrationsList
              .filter((integration) => integration.pageState === PageState.INACTIVE)
              .flatMap((inactiveIntegration) => inactiveIntegration.integrations).length > 0 && (
              <>
                <div className="flex">
                  <p className="text-black text-lg">Available</p>
                </div>
                {integrationsList
                  .filter((integration) => integration.pageState === PageState.INACTIVE)
                  .flatMap((inactiveIntegration) => inactiveIntegration.integrations)
                  .map((integration) => (
                    <IntegrationCard
                      key={integration.serverValue}
                      integration={integration}
                      integrationName={integration.name}
                      integrationDescription={integration.description}
                      logoUrl={integration.image}
                      docsUrl={integration.docsLink}
                      manageIntegration={manageIntegration}
                      notConfigured={true}
                    />
                  ))}
              </>
            )}

            <div className="mx-auto mt-10">
              <div className="text-center text-black text-lg leading-relaxed font-medium">
                Can’t find the integration you need?
              </div>
              <div className="max-w-[388px] text-center text-black text-sm font-normal leading-tight mx-auto mt-4">
                Our team is here to help! Contact our sales team, and we can work with your to discover the perfect
                solution.
              </div>
              <div className="flex justify-center mt-6">
                <div
                  className="h-8 px-3 bg-neutral-1 rounded border border-neutral-5 justify-center items-center gap-2 inline-flex cursor-pointer"
                  onClick={() => (window.location.href = 'mailto:sales@iverify.io')}
                >
                  <div className="text-neutral-12 text-sm leading-tight">Contact Sales</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showManageIntegrationPopout && (
        <IntegrationPopout popoutIntegration={popoutIntegration} setShowPopout={setShowManageIntegrationPopout} />
      )}
    </>
  )
}

export default IntegrationsWrapper
