import { Button } from '@radix-ui/themes'
import {
  settingsUserNavItems,
  settingsAppNavItems,
  settingsLoginNavItems,
  settingsLogoutNavItems,
  NavItems,
} from '../../data/settings-nav-items'
import { useDispatch, useSelector } from 'react-redux'
import { selectSettings, setCurrentPage } from '../../reducers/settings'
import {
  BellIcon,
  Crosshair2Icon,
  ExitIcon,
  IdCardIcon,
  Link2Icon,
  PersonIcon,
  RadiobuttonIcon,
} from '@radix-ui/react-icons'
import { logoutUser, selectUser } from '../../reducers/users'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../reducers/store'
import { useEffect, useState } from 'react'
import React from 'react'
import { RoleType } from '../../types/UserTypes'
import { getRoles, permitsRole } from '../../utils/roles'

function SettingsNavMenu() {
  const { currentPage } = useSelector(selectSettings)
  const { session } = useSelector(selectUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const di = useAppDispatch()

  const roles = getRoles(session)
  const [appNavItems, setAppNavItems] = useState<NavItems[]>([])

  useEffect(() => {
    if (session && session.portalPreferences.threatHunterAccess && permitsRole(roles)) {
      setAppNavItems([
        ...settingsAppNavItems.filter((nav) => roles && permitsRole(roles, nav.roles)),
        { type: 'THREAT_HUNTER', display: 'Threat Hunter' },
      ])
    } else {
      setAppNavItems(settingsAppNavItems.filter((nav) => roles && permitsRole(roles, nav.roles)))
    }
  }, [session])

  const selectIcon = (item: string) => {
    switch (item) {
      case 'BILLING':
        return <BellIcon width="20" height="20" />
      case 'PLAN':
        return <IdCardIcon width="20" height="20" />
      case 'MDM':
        return <Link2Icon width="20" height="20" />
      case 'SCIM':
        return <Link2Icon width="20" height="20" />
      case 'SSO':
        return <Link2Icon width="20" height="20" />
      case 'INTEGRATIONS':
        return <Link2Icon width="20" height="20" />
      case 'NOTIFICATION':
        return <RadiobuttonIcon width="20" height="20" />
      case 'THREAT_HUNTER':
        return <Crosshair2Icon width="20" height="20" />
      case 'SIGN_OUT':
        return <ExitIcon width="20" height="20" />
      default:
        return <PersonIcon width="20" height="20" />
    }
  }

  const logout = async () => {
    await di(logoutUser())
    localStorage.removeItem('DASHBOARD_PAGE_COOKIE')
    localStorage.removeItem('SETTINGS_PAGE_COOKIE')
    navigate('/login')
  }

  const filteredLoginNavItems = settingsLoginNavItems.filter((nav) => roles && permitsRole(roles, nav.roles))

  return (
    <>
      {/* Nav menu */}
      <div className="w-[180px] mt-20">
        <div className="w-[176px]">
          {roles?.includes(RoleType.SUPER_ADMIN) && (
            <>
              <p className="text-xs font-semibold text-darkGreyBlue ml-4 mb-2">User Settings</p>
              {settingsUserNavItems.map((item) => (
                <Button
                  key={item.type}
                  variant="solid"
                  size="3"
                  className={`h-10 w-[176px] ${currentPage === item.type ? 'bg-PurpleBlue-10 text-white' : 'bg-white text-black'} justify-start cursor-pointer`}
                  onClick={() => {
                    dispatch(setCurrentPage(item.type))
                    localStorage.setItem('SETTINGS_PAGE_COOKIE', item.type)
                  }}
                >
                  {React.createElement(item.icon)}
                  {item.display}
                </Button>
              ))}
              <hr className="my-3 mx-4" />
            </>
          )}

          {filteredLoginNavItems.length > 0 && (
            <p className="text-xs font-semibold text-darkGreyBlue ml-4 mb-2">Login Settings</p>
          )}
          {filteredLoginNavItems.map((item) => (
            <Button
              key={item.type}
              variant="solid"
              size="3"
              className={`h-10 w-[176px] ${currentPage === item.type ? 'bg-PurpleBlue-10 text-white' : 'bg-white text-black'} justify-start cursor-pointer`}
              onClick={() => {
                dispatch(setCurrentPage(item.type))
                localStorage.setItem('SETTINGS_PAGE_COOKIE', item.type)
              }}
            >
              {selectIcon(item.type)}
              {item.display}
            </Button>
          ))}
          {filteredLoginNavItems.length > 0 && <hr className="my-3 mx-4" />}
          {appNavItems.length > 0 && <p className="text-xs font-semibold text-darkGreyBlue ml-4 mb-2">App Settings</p>}
          {appNavItems.map((item) => (
            <Button
              key={item.type}
              variant="solid"
              size="3"
              className={`h-10 w-[176px] ${currentPage === item.type ? 'bg-PurpleBlue-10 text-white' : 'bg-white text-black'} justify-start cursor-pointer`}
              onClick={() => {
                dispatch(setCurrentPage(item.type))
                localStorage.setItem('SETTINGS_PAGE_COOKIE', item.type)
              }}
            >
              {selectIcon(item.type)}
              {item.display}
            </Button>
          ))}
          {appNavItems.length > 0 && <hr className="my-3 mx-4" />}
          {settingsLogoutNavItems.map((item) => (
            <Button
              key={item.type}
              variant="solid"
              size="3"
              className={`h-10 w-[176px] ${currentPage === item.type ? 'bg-PurpleBlue-10 text-white' : 'bg-white text-black'} justify-start cursor-pointer`}
              onClick={() => logout()}
            >
              {selectIcon(item.type)}
              {item.display}
            </Button>
          ))}
        </div>
      </div>
    </>
  )
}

export default SettingsNavMenu
