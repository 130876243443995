import { IconHistory, IconUser } from '@tabler/icons-react'
import { RoleType } from '../types/UserTypes'

export const settingsUserNavItems = [
  {
    type: 'ADMIN',
    display: 'Admin',
    icon: IconUser,
    roles: [RoleType.SUPER_ADMIN],
  },
  {
    type: 'AUDIT',
    display: 'Audit Log',
    icon: IconHistory,
    roles: [RoleType.SUPER_ADMIN],
  },
]

export const settingsLoginNavItems = [
  {
    type: 'MDM',
    display: 'MDM',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
  {
    type: 'SCIM',
    display: 'SCIM',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
  {
    type: 'SSO',
    display: 'SSO',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
  {
    type: 'INTEGRATIONS',
    display: 'Integrations',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT, RoleType.ACCESS_MGMT],
  },
]

export const settingsAppNavItems = [
  {
    type: 'NOTIFICATION',
    display: 'Notifications',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
]

export type NavItems = {
  type: string
  display: string
}

export const settingsLogoutNavItems = [
  {
    type: 'SIGN_OUT',
    display: 'Sign Out',
  },
]
