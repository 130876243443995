import { Cross2Icon } from '@radix-ui/react-icons'
import { Admin } from '../../types/SettingsType'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { addUpdateAdminSchema } from '../../schemas/SettingsSchema'
import { Button } from '@radix-ui/themes'
import { addAdmin, fetchAdmins, updateAdmin } from '../../reducers/settings'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import RadioButtonGroup from '../dropdown/RadioButtonGroup'
import { rolesOptions } from '../../strings/dropdown-options'
import { SelectOption } from '../../types/DropDownTypes'
import { FormInput } from '../input/FormInput'
import { RoleType, SessionState } from '../../types/UserTypes'

interface IPopoutProps {
  popoutData: Admin
  setShowPopout: (show: boolean) => void
  triggerEvent?: (event: string, data: Admin) => void
}

const AdminPopoutContent = ({ popoutData, setShowPopout, triggerEvent }: IPopoutProps) => {
  const [loading, setLoading] = useState(false)
  const [rolesArray, setRolesArray] = useState<SelectOption[]>(popoutData.roles)
  const currentSession = JSON.parse(localStorage.getItem('session') as string) as SessionState
  const isUpdate = popoutData.email ? true : false
  const editingSelf = popoutData.email === currentSession.user.email

  const di = useAppDispatch()

  const availableRoles: SelectOption[] = editingSelf
    ? rolesOptions.map((role) => ({ ...role, disabled: true }))
    : rolesOptions

  useEffect(() => {
    setRolesArray(popoutData.roles)
    setValue('roles', popoutData.roles)
    setValue('name', popoutData.name)
    setValue('email', popoutData.email)
  }, [popoutData])

  const updateRoles = (newRoles: SelectOption[], newRole?: SelectOption) => {
    if (
      newRoles &&
      (newRoles.includes(availableRoles[1]) || newRoles.includes(availableRoles[2])) &&
      !newRoles.includes(availableRoles[3]) &&
      !newRoles.includes(availableRoles[0])
    ) {
      toast.error(`The ${RoleType.GENERAL_ADMIN} role is required to work with the other selected roles`, {
        duration: 6000,
      })
      newRoles.push(availableRoles[3])
      setRolesArray(newRoles)
      setValue('roles', newRoles)
    }

    if (newRoles.includes(availableRoles[0]) && newRole && newRoles.length > 1) {
      toast.error(`${RoleType.SUPER_ADMIN} will supersede all your other selected roles`, {
        duration: 6000,
      })
    }

    setRolesArray(newRoles)
    setValue('roles', newRoles)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Admin>({ resolver: yupResolver(addUpdateAdminSchema) })

  const onSubmit: SubmitHandler<Admin> = async (admin: Admin) => {
    setLoading(true)
    const result = await di(isUpdate ? updateAdmin(admin, popoutData.email) : addAdmin(admin))
    setLoading(false)
    if (!result) {
      toast.error(`An error occurred while ${isUpdate ? 'updating' : 'adding'} the admin`, { duration: 6000 })
    } else {
      di(fetchAdmins())
      toast.success(`Successfully ${isUpdate ? 'updated' : 'added'} admin`, {
        duration: 6000,
      })
      triggerEvent?.('update', { ...popoutData, roles: admin.roles, name: admin.name, email: admin.email })
      setShowPopout(false)
    }
  }

  return (
    <>
      <div className="flex flex-col h-screen gap-2">
        <div className="font-semibold border-b flex px-6 py-3 bg-neutral-2 text-lg">
          {isUpdate ? 'Manage Admin' : 'Invite Admin'}
          <div className="flex justify-end items-center pr-2 flex-grow">
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowPopout(false)
              }}
            >
              <Cross2Icon className="w-6 h-6" />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-1 flex-col gap-4 px-8">
          <div className="text-sm leading-normal flex flex-col gap-2">
            <div className="font-semibold">Full Name</div>
            <FormInput value={popoutData.name} {...register('name')} placeholder="Full Name" disabled={editingSelf} />
            {errors.name && <p className="text-ErrorRed">{errors.name.message}</p>}
          </div>
          <div className="text-sm leading-normal flex flex-col gap-2">
            <div className="font-semibold">Email</div>
            <FormInput
              value={popoutData.email}
              {...register('email')}
              placeholder="name@example.com"
              disabled={editingSelf}
            />
            {errors.email && <p className="text-ErrorRed">{errors.email.message}</p>}
          </div>
          <div className="text-sm leading-normal flex flex-col gap-2">
            <div className="font-semibold">Roles</div>
            <RadioButtonGroup value={rolesArray} options={availableRoles} onChangeHandler={updateRoles} />
            {errors.roles && <p className="text-ErrorRed">{errors.roles.message}</p>}
          </div>
          {!editingSelf && (
            <div className="flex justify-end">
              <Button
                type="submit"
                className={`h-10 px-4 py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ' cursor-pointer'} bg-PurpleBlue-10 text-white`}
                disabled={loading}
              >
                {loading ? 'Updating...' : isUpdate ? 'Save Changes' : 'Send Invite'}
              </Button>
            </div>
          )}
        </form>
        <div className="flex gap-2 justify-end align-bottom m-4">
          {isUpdate && (
            <>
              <Button
                onClick={() => triggerEvent?.('delete', popoutData)}
                className={`h-10 px-4 py-2 bg-ErrorRed ${loading ? 'opacity-50 cursor-not-allowed ' : ' cursor-pointer'} text-white`}
              >
                Remove Admin
              </Button>
              {!popoutData.inviteAccepted && (
                <Button
                  onClick={() => triggerEvent?.('invite', popoutData)}
                  className={`h-10 px-4 py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ' cursor-pointer'} bg-PurpleBlue-10 text-white`}
                >
                  Resend Invite
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AdminPopoutContent
