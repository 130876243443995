import Server from '../../api/server'
import {
  ANDROID_DEVICE_ROUTE,
  IOS_DEVICE_ROUTE,
  EMAIL_USERS_ROUTE,
  UNENROLL_DEVICES_ROUTE,
  DEVICES_ROUTE,
  INSECURE_DEVICE_ROUTE,
  DASHBOARD_STATS_ROUTE,
  UPDATE_DEVICE_NAME_ROUTE,
  DASHBOARD_DEVICES_CREATED_STAT_ROUTE,
  DASHBOARD_ALERTS_CREATED_STAT_ROUTE,
  DASHBOARD_TOTAL_SCANS_STAT_ROUTE,
  DASHBOARD_INSECURE_DEVICES_STAT_ROUTE,
  DEVICE_DETAILS_ROUTE,
  API_URL,
} from '../../strings/api-consts'
import {
  DashboardStats,
  DeviceInformation,
  Devices,
  EmailUserCount,
  InsecureDeviceInformation,
  Stats,
} from '../../types/DeviceTypes'
import { SelectOption } from '../../types/DropDownTypes'
import { CustomThunkDispatch } from '../../types/UserTypes'
import {
  setCurrentPage,
  setDashboardAlertsCreatedStat,
  setDashboardDevicesCreatedStat,
  setDashboardDeviceStats,
  setDashboardInsecureDevicesStat,
  setDashboardTotalScansStat,
  setDevices,
  setInsecureDevices,
  setTotalPages,
} from './devices.slice'
import * as Sentry from '@sentry/react'

const server = new Server()

export const fetchDevices =
  (pageNumber: number, pageSize: number, severityFilter: SelectOption[], platform: string, searchTerm: string) =>
  async (dispatch: CustomThunkDispatch) => {
    const platformRoute = () => {
      if (platform === 'android') {
        return ANDROID_DEVICE_ROUTE
      } else if (platform === 'ios') {
        return IOS_DEVICE_ROUTE
      } else if (platform === 'not_activated') {
        return DEVICES_ROUTE
      }
    }

    let url = `${platformRoute()}?page=${pageNumber}&pageSize=${pageSize}`

    if (platform === 'not_activated') {
      url += '&enrolled=false'
    }

    if (searchTerm && searchTerm !== '') {
      const encodedSearchTerm = encodeURIComponent(searchTerm)
      url += `&name=${encodedSearchTerm}`
    }

    if (severityFilter.length > 0 && severityFilter.length !== 3) {
      const severityParams = severityFilter.map((severity) => `&severity=${severity.value}`).join('')
      url += severityParams
    } else if (severityFilter.length === 3) {
      url += ''
    }
    await server
      .get(url, dispatch)
      .then((deviceObject: Devices) => {
        dispatch(setCurrentPage(pageNumber - 1))
        dispatch(setTotalPages(deviceObject.totalPages))
        dispatch(setDevices(deviceObject.devices))
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
      })
  }

export const fetchDeviceDetails = (deviceId: string) => async () => {
  const url = API_URL + DEVICE_DETAILS_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ deviceId: deviceId }),
  })
    .then((res) => res.json())
    .then((deviceObject: DeviceInformation) => {
      return deviceObject
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return undefined
    })
}

export const fetchInsecureDevices = () => async (dispatch: CustomThunkDispatch) => {
  await server
    .get(INSECURE_DEVICE_ROUTE, dispatch)
    .then((deviceObject: InsecureDeviceInformation[]) => {
      dispatch(setInsecureDevices(deviceObject))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const emailMembers = (codes: string[]) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(EMAIL_USERS_ROUTE, codes, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((count: EmailUserCount) => {
      return count.count
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return undefined
    })
}

export const removeDevices = (codes: string[]) => async () => {
  const url = API_URL + UNENROLL_DEVICES_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(codes),
  })
    .then((res) => {
      return res.ok
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const fetchDashStats = () => async (dispatch: CustomThunkDispatch) => {
  server
    .get(DASHBOARD_STATS_ROUTE, dispatch)
    .then((stats: DashboardStats) => {
      dispatch(setDashboardDeviceStats(stats))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const fetchDevicesCreatedStats = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + DASHBOARD_DEVICES_CREATED_STAT_ROUTE

  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((stat: Stats) => {
      dispatch(setDashboardDevicesCreatedStat(stat))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const fetchAlertsCreatedStats = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + DASHBOARD_ALERTS_CREATED_STAT_ROUTE

  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((stat: Stats) => {
      dispatch(setDashboardAlertsCreatedStat(stat))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const fetchTotalScansStats = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + DASHBOARD_TOTAL_SCANS_STAT_ROUTE

  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((stat: Stats) => {
      dispatch(setDashboardTotalScansStat(stat))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const fetchInsecureDevicesStats = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + DASHBOARD_INSECURE_DEVICES_STAT_ROUTE

  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((stat: Stats) => {
      dispatch(setDashboardInsecureDevicesStat(stat))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const updateDeviceName =
  (accessCode: string, deviceName: string) =>
  async (dispatch: CustomThunkDispatch): Promise<boolean> => {
    const payload = {
      accessCode: accessCode,
      deviceName: deviceName,
    }

    return server
      .post(UPDATE_DEVICE_NAME_ROUTE, payload, dispatch)
      .then((res) => {
        return res?.ok ?? false
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
        return false
      })
  }
