import { Button } from '@radix-ui/themes'
import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { addMembers, fetchMembers, selectMembers } from '../../reducers/members'
import { SsoMember } from '../../types/SettingsType'
import toast from 'react-hot-toast'
import Papa from 'papaparse'
import { useSelector } from 'react-redux'

interface ISsoGroupsPageProps {
  setCurrentPage: (page: string) => void
  setShowCSVModal: (show: boolean) => void
  uploadedFiles: File[]
}

function CsvInvitePage(props: ISsoGroupsPageProps) {
  const { setCurrentPage, setShowCSVModal, uploadedFiles } = props
  const [filteredMembers, setFilteredMembers] = useState<SsoMember[] | undefined>(undefined)
  const [isDuplicates, setIsDuplicates] = useState<boolean>(false)
  const [newCodesNumber, setNewCodesNumber] = useState<number>(0)
  const di = useAppDispatch()
  const { members } = useSelector(selectMembers)

  const handleSendInvitations = async () => {
    const membersWithId = filteredMembers!.map((member, index) => ({
      ...member,
      id: (index + 1).toString(),
    }))
    const newMembers = await di(addMembers(membersWithId))
    if (newMembers.error) {
      toast.error(newMembers.error, { duration: 6000 })
    } else {
      di(fetchMembers(undefined, '', undefined, undefined))
      toast.success(`Successfully added ${membersWithId.length} new member${membersWithId.length === 1 ? '' : 's'}`, {
        duration: 6000,
      })
      setShowCSVModal(false)
    }
  }

  useEffect(() => {
    di(fetchMembers())
    Papa.parse(uploadedFiles[0], {
      header: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      complete: function (results: any) {
        const {
          data,
          meta: { fields },
        } = results

        if (!fields.includes('fullname') || !fields.includes('email')) {
          toast.error('CSV file must have fullname and email headers', { duration: 6000 })
          setCurrentPage('UPLOAD')
        }

        const filteredData = data
          .filter(
            ({ fullname, email }: { fullname: string; email: string }) =>
              fullname && email && fullname.trim() !== '' && email.trim() !== ''
          )
          .map(({ fullname, email }: { fullname: string; email: string }) => ({
            name: fullname,
            email: email,
          }))

        setFilteredMembers(filteredData)
      },
    })
  }, [])

  useEffect(() => {
    if (members) {
      const duplicatedMembers = fetchMembersAndSetDuplicates()
      setFilteredMembers(duplicatedMembers)
    }
  }, [members])

  const fetchMembersAndSetDuplicates = () => {
    return filteredMembers?.map((csvMember) => {
      const orgDuplicate = members?.find(
        (orgMember) => orgMember.email.toLocaleLowerCase() === csvMember?.email?.toLocaleLowerCase()
      )
      if (orgDuplicate) {
        setIsDuplicates(true)
        return {
          ...csvMember,
          duplicate: true,
        }
      } else {
        return csvMember
      }
    })
  }

  const setDuplicateValues = (email: string) => {
    const updatedMembers = filteredMembers!.map((filteredMember) =>
      filteredMember.email === email ? { ...filteredMember, duplicate: !filteredMember.duplicate } : filteredMember
    )
    setFilteredMembers(updatedMembers)
  }

  useEffect(() => {
    if (filteredMembers) {
      const countNonDuplicates = filteredMembers.filter((member) => !member.duplicate).length
      setNewCodesNumber(countNonDuplicates)
    }
  }, [filteredMembers])

  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-[500px]">
          <p className="text-center">
            An email will be sent to each individual with a unique code to enroll their device in your organization.
          </p>
          {isDuplicates && (
            <>
              <p className="italic text-sm mt-4 text-center">Devices are already registered for the crossed members.</p>
              <p className="italic text-sm mt-2 text-center">
                Click on the crossed members to add them back to the list and register additional devices.
              </p>
            </>
          )}
          <p className="italic text-sm mt-2 text-center">
            {newCodesNumber === 0
              ? 'No new members will be added. Undo deletion to send new access codes.'
              : `${newCodesNumber} code${newCodesNumber === 1 ? '' : 's'} will be added and billing will be updated to reflect the new count.`}
          </p>
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <div className="block max-w-[700px]">
          <div className="flex justify-end mt-4 cursor-pointer" onClick={() => setCurrentPage('UPLOAD')}>
            <ArrowLeftIcon color="indigo" className="mt-0.5 mr-1" />
            <p className="!text-PurpleBlue-10 text-sm font-semibold">Return to Previous Step</p>
          </div>
          <div className="max-w-[700px] h-[300px] border border-lightGrey rounded p-3 bg-tableHeaderGrey mt-1 overflow-scroll">
            {filteredMembers && (
              <table className="table-auto max-w-[670px]">
                <thead>
                  <tr>
                    <th className="text-left">Name</th>
                    <th className="text-left">Email</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="max-h-[270px] overflow-scroll">
                  {filteredMembers.map((member) => (
                    <tr
                      className={'duplicate' in member ? 'cursor-pointer' : ''}
                      onClick={() => {
                        if ('duplicate' in member) {
                          setDuplicateValues(member.email)
                        }
                      }}
                      key={member.email}
                    >
                      <td className={member.duplicate ? 'line-through' : ''}>{member.name}</td>
                      <td className={member.duplicate ? 'line-through' : ''}>{member.email}</td>
                      <td>{'duplicate' in member ? 'duplicate' : ''}</td>
                      <td className="text-right text-PurpleBlue-10 cursor-pointer">
                        {member.duplicate === true ? 'add' : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <Button
            type="submit"
            className="w-[500px] h-[36px] rounded mt-[19px] ml-auto text-white font-semibold bg-PurpleBlue-10 text-sm cursor-pointer"
            value="Add"
            onClick={handleSendInvitations}
            disabled={newCodesNumber === 0}
          >
            <p>Send Invitations</p>
          </Button>
        </div>
      </div>
    </>
  )
}

export default CsvInvitePage
