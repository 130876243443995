import { forwardRef, useEffect, useState } from 'react'
import './input.css'
import { ChangeHandler } from 'react-hook-form'

export interface IInputProps {
  value?: string
  placeholder?: string
  onChange?: ChangeHandler
  disabled?: boolean
  type?: string
  error?: boolean
  maxLength?: number
  hasButton?: boolean
}

export const FormInput = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      value,
      placeholder,
      onChange,
      disabled = false,
      type = 'text',
      error = false,
      maxLength = 2000,
      hasButton = false,
      ...props
    },
    ref
  ) => {
    const [currentValue, setValue] = useState(value || '')
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e)
      }
      setValue(e.target.value)
    }

    useEffect(() => {
      setValue(value || '')
    }, [value])

    return (
      <input
        value={currentValue}
        type={type}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e)}
        disabled={disabled}
        className={`${disabled ? 'disabled' : ''} ${error ? 'error' : ''} ${hasButton ? 'pr-10' : ''}`}
        maxLength={maxLength}
        ref={ref}
        {...props}
      />
    )
  }
)
