import Server from '../../api/server'
import {
  API_URL,
  DELETE_BLOCKED_DOMAIN_ROUTE,
  GET_DOMAIN_BLOCKLIST_LOGS_ROUTE,
  GET_DOMAIN_BLOCKLIST_ROUTE,
  POST_DOMAIN_TO_BLOCKLIST_ROUTE,
} from '../../strings/api-consts'
import { AddDomainInputs, Blocklist, BlocklistDomainsResponse, BlocklistLog } from '../../types/BlocklistTypes'
import { CustomThunkDispatch } from '../../types/UserTypes'
import { setBlocklist, setBlocklistLogs, setNoDomains, setNoLogs } from './blocklist.slice'
import * as Sentry from '@sentry/react'

const server = new Server()

export const fetchBlocklist = (name?: string) => async (dispatch: CustomThunkDispatch) => {
  let url = GET_DOMAIN_BLOCKLIST_ROUTE

  if (name) {
    url = `${url}?name=${name}`
  }

  return server
    .get(url, dispatch)
    .then((blocklist: Blocklist) => {
      dispatch(setBlocklist(blocklist))
      if (blocklist?.error || blocklist?.domains?.length === 0) {
        dispatch(setNoDomains(true))
        return false
      }
      dispatch(setNoDomains(false))
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      dispatch(setBlocklist(undefined))
      dispatch(setNoDomains(true))
      return false
    })
}

export const fetchBlocklistLogs = (name?: string) => async (dispatch: CustomThunkDispatch) => {
  let url = GET_DOMAIN_BLOCKLIST_LOGS_ROUTE

  if (name) {
    url = `${url}?name=${name}`
  }

  return server
    .get(url, dispatch)
    .then((blocklist: BlocklistLog[]) => {
      dispatch(setBlocklistLogs(blocklist))
      if (blocklist?.length === 0) {
        dispatch(setNoLogs(true))
        return false
      }
      dispatch(setNoLogs(false))
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      dispatch(setBlocklistLogs(undefined))
      dispatch(setNoLogs(true))
      return false
    })
}

export const postDomainsToBlocklist = (domains: AddDomainInputs[]) => async () => {
  const url = API_URL + POST_DOMAIN_TO_BLOCKLIST_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(domains),
  })
    .then((res) => res.json())
    .then((blocklistDomainsResponse: BlocklistDomainsResponse) => {
      return blocklistDomainsResponse.matchedWhitelistDomains
    })
    .catch(() => {
      return undefined
    })
}

export const deleteBlockedDomain = (domainId: number) => async (dispatch: CustomThunkDispatch) => {
  return server
    .delete(DELETE_BLOCKED_DOMAIN_ROUTE, dispatch, { id: domainId })
    .then((response) => {
      if (response?.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(() => {
      return false
    })
}
