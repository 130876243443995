/* eslint-disable @typescript-eslint/no-explicit-any */

import Select, { StylesConfig } from 'react-select'
import chroma from 'chroma-js'

interface IDropDownProps {
  options: any
  value: any
  onChangeHandler: (newValue: any) => void
  style: string
  placeholder: string
  multiple: boolean
  disabled: boolean
}

function DropDown(props: IDropDownProps) {
  const { options, value, onChangeHandler, style, placeholder, multiple, disabled } = props

  const colourStyles: StylesConfig<any, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),

    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: chroma(data.bgColor).css(),
        marginTop: '-4px',
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: '38px',
      minHeight: '38px',
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    placeholder: (base: any) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
      paddingTop: '0px',
    }),
    input: (base: any) => ({
      ...base,
      height: '36px',
      minHeight: '36px',
      marginTop: '0px',
      marginBottom: '0px',
      paddingTop: '0px',
    }),
  }

  return (
    <Select
      value={value}
      onChange={(e: any) => onChangeHandler(e)}
      options={options}
      className={style}
      placeholder={placeholder}
      styles={{ ...colourStyles, ...customStyles }}
      isMulti={multiple ? true : undefined}
      isDisabled={disabled ? true : undefined}
    />
  )
}

export default DropDown