import {
  setAdmins,
  setApiKey,
  setWebhookSecret,
  setWebhook,
  setSsoGroups,
  setSsoMembers,
  setScim,
  setMdm,
  setEmailPreference,
  setNotificationPreference,
  setEmailEnrollmentTemplate,
  setThreatHunterUsers,
  setAuditLogs,
  setAuditLogsTotalPages,
  setAuditLogsCurrentPage,
} from '.'
import Server from '../../api/server'
import {
  ADMINS_ROUTE,
  RESEND_INVITE_ADMIN_ROUTE,
  ADD_ADMIN_ROUTE,
  SSO_ROUTE,
  SSO_GROUPS_ROUTE,
  CREATE_SSO_MEMBERS_ROUTE,
  API_ROUTE,
  WEBHOOK_ROUTE,
  WEBHOOK_SECRET_ROUTE,
  SCIM_ROUTE,
  MDM_ROUTE,
  EMAIL_PREFERENCES_ROUTE,
  NOTIFICATION_PREFERENCES_ROUTE,
  ENROLLMENT_EMAIL_TEMPLATE_ROUTE,
  PUT_ENROLLMENT_EMAIL_TEMPLATE_ROUTE,
  THREAT_HUNTER_USERS_ROUTE,
  ADD_THREAT_HUNTER_USER_ROUTE,
  CONDITIONAL_ACCESS_CONFIG_ROUTE,
  CONDITIONAL_ACCESS_POLICY_ROUTE,
  CONDITIONAL_ACCESS_DELETE_ROUTE,
  DELETE_ADMIN_ROUTE,
  AUDIT_ROUTE,
  UPDATE_ADMIN_ROUTE,
  SCIM_ENABLED_ROUTE,
} from '../../strings/api-consts'
import { rolesOptions } from '../../strings/dropdown-options'
import {
  AddSsoSettings,
  AddThreatHunterUser,
  Admin,
  AdminResponse,
  ApiKey,
  AuditLogFilter,
  AuditLogs,
  ConditionalAccessPolicyResponse,
  ConditionalAccessResponse,
  CreateSSOMemberResponse,
  CreateSsoMembersPayload,
  EmailPreferences,
  EnrollEmailTemplate,
  Mdm,
  MemberGroup,
  MembersResponse,
  NotificationPreferences,
  Scim,
  ScimEnabled,
  SsoGroups,
  ThreatHunterUsersResponse,
  WebhookPayload,
  WebhookSecret,
} from '../../types/SettingsType'
import { CustomThunkDispatch } from '../../types/UserTypes'
import * as Sentry from '@sentry/react'

const server = new Server()

export const fetchAdmins = () => async (dispatch: CustomThunkDispatch) => {
  const admins = await server.get(ADMINS_ROUTE, dispatch)
  const mappedAdmins = (admins as AdminResponse).admins.map((admin) => {
    return {
      ...admin,
      roles: rolesOptions.filter((role) => admin.roles?.includes(role.label)),
    } as Admin
  })
  dispatch(setAdmins(mappedAdmins))
}

export const resendAdminInvite = (adminInfo: Admin) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(
      RESEND_INVITE_ADMIN_ROUTE,
      {
        name: adminInfo.name,
        email: adminInfo.email,
        roles: adminInfo.roles.map((role) => role.label).join(','),
      },
      dispatch
    )
    .then(() => {
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const addAdmin = (adminInfo: Admin) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(
      ADD_ADMIN_ROUTE,
      {
        name: adminInfo.name,
        email: adminInfo.email,
        roles: adminInfo.roles.map((role) => role.label).join(','),
      },
      dispatch
    )
    .then((response) => {
      return response?.ok ?? false
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const updateAdmin = (adminInfo: Admin, oldEmail: string) => async (dispatch: CustomThunkDispatch) => {
  return await server
    .patch(
      UPDATE_ADMIN_ROUTE,
      {
        name: adminInfo.name,
        email: oldEmail,
        newEmail: adminInfo.email,
        roles: adminInfo.roles.map((role) => role.label).join(','),
      },
      dispatch
    )
    .then((response) => {
      return response?.ok ?? false
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const deleteAdmin = (email: string) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(DELETE_ADMIN_ROUTE, { email }, dispatch)
    .then((response) => {
      return response?.ok ?? false
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const fetchAuditLogs =
  (
    pageNumber: number,
    pageSize: number,
    filters: AuditLogFilter,
    sortOrder?: string,
    sortColumn?: string,
    key = null,
    value = null
  ) =>
  async (dispatch: CustomThunkDispatch) => {
    let url = `${AUDIT_ROUTE}?page=${pageNumber}&pageSize=${pageSize}`
    const { fromDateTime, toDateTime } = filters
    if (sortColumn) {
      url += `&sortColumn=${sortColumn}`
      if (sortOrder) {
        url += `&sortOrder=${sortOrder}`
      }
    }

    if (fromDateTime) {
      url += `&fromDateTime=${fromDateTime}`
    }

    if (toDateTime) {
      url += `&toDateTime=${toDateTime}`
    }

    if (key && value) {
      url += `&key=${key}&value=${value}`
    }

    return await server
      .get(url, dispatch)
      .then((audits: AuditLogs) => {
        dispatch(setAuditLogsCurrentPage(!pageNumber ? 0 : pageNumber - 1))
        dispatch(setAuditLogs(audits.data))
        dispatch(setAuditLogsTotalPages(audits.totalPages))
        return true
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
        return false
      })
  }

export const applySsoSettings = (adminInfo: AddSsoSettings) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(SSO_ROUTE, adminInfo, dispatch)
    .then((response) => {
      if (response?.ok) {
        localStorage.setItem('SSO_CONFIGURED_COOKIE', 'true')
        window.location.href = import.meta.env.VITE_API_ENDPOINT + `/oauth/login?slug=${adminInfo.slug}`
        return true
      }
      return false
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const deleteSsoSettings = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .delete(SSO_ROUTE, dispatch)
    .then((response) => {
      return response?.ok ?? false
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const fetchSsoGroups = () => async (dispatch: CustomThunkDispatch) => {
  await server
    .get(SSO_GROUPS_ROUTE, dispatch)
    .then((ssoGroups: SsoGroups) => {
      dispatch(setSsoGroups(ssoGroups))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      dispatch(setSsoGroups(undefined))
    })
}

export const fetchSsoMembersForGroups = (payload: MemberGroup[]) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(SSO_GROUPS_ROUTE, payload, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((ssoMembers: MembersResponse) => {
      dispatch(setSsoMembers(ssoMembers))
      return ssoMembers
    })
    .catch((err) => {
      dispatch(setSsoMembers(undefined))
      Sentry.captureMessage(err.message)
      return undefined
    })
}

export const fetchAPIKey = () => async (dispatch: CustomThunkDispatch) => {
  await server
    .get(API_ROUTE, dispatch)
    .then((apiKey: ApiKey) => {
      dispatch(setApiKey(apiKey))
    })
    .catch((err) => {
      dispatch(setApiKey(undefined))
      Sentry.captureMessage(err.message)
      return false
    })
}

export const generateAPIKey = (expiration: string) => async (dispatch: CustomThunkDispatch) => {
  await server
    .post(API_ROUTE, { tokenExpiration: expiration }, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((apiKey: ApiKey) => {
      dispatch(setApiKey(apiKey))
    })
    .catch((err) => {
      dispatch(setApiKey(undefined))
      Sentry.captureMessage(err.message)
      return false
    })
}

export const revokeAPIKey = () => async (dispatch: CustomThunkDispatch) => {
  await server
    .delete(API_ROUTE, dispatch)
    .then(() => {
      dispatch(setApiKey(undefined))
    })
    .catch((err) => {
      dispatch(setApiKey(undefined))
      Sentry.captureMessage(err.message)
      return false
    })
}

export const fetchWebhook = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(WEBHOOK_ROUTE, dispatch)
    .then((webhook: WebhookPayload) => {
      dispatch(setWebhook(webhook))
      dispatch(setWebhookSecret({ secret: webhook.secret }))
      return webhook
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return undefined
    })
}

export const createSsoMembers = (membersPayload: CreateSsoMembersPayload) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(CREATE_SSO_MEMBERS_ROUTE, membersPayload, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((response: CreateSSOMemberResponse) => {
      return response
    })
    .catch((err) => {
      return err
    })
}

export const fetchWebhookSecret = () => async (dispatch: CustomThunkDispatch) => {
  await server
    .get(WEBHOOK_SECRET_ROUTE, dispatch)
    .then((apiKey: WebhookSecret) => {
      dispatch(setWebhookSecret(apiKey))
    })
    .catch((err) => {
      dispatch(setWebhookSecret(undefined))
      Sentry.captureMessage(err.message)
      return false
    })
}

export const saveWebhook = (payload: WebhookPayload) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(WEBHOOK_ROUTE, payload, dispatch)
    .then((res) => {
      if (res?.status === 200) {
        dispatch(setWebhook(payload))
        return { success: res.ok, message: 'Webhook has been successfully set up' }
      }
      return res?.text().then((body) => {
        return { success: res.ok, message: body }
      })
    })
    .catch((err) => {
      return { success: false, message: err.message }
    })
}

export const deactivateWebhook = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .delete(WEBHOOK_ROUTE, dispatch)
    .then(() => {
      dispatch(setWebhook(undefined))
    })
    .catch(() => {
      dispatch(setWebhook(undefined))
    })
}

export const fetchScimStatus = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(SCIM_ROUTE, dispatch)
    .then((scim: Scim) => {
      dispatch(setScim(scim))
    })
    .catch(() => {
      dispatch(setScim({ enabled: false, scimUrl: '', authHeader: '' }))
    })
}

export const fetchScimEnabled = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(SCIM_ENABLED_ROUTE, dispatch)
    .then((result: ScimEnabled) => {
      dispatch(setScim({ enabled: result.enabled, scimUrl: '', authHeader: '' }))
    })
    .catch(() => {
      dispatch(setScim({ enabled: false, scimUrl: '', authHeader: '' }))
    })
}

export const updateScimStatus = (status: boolean) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(SCIM_ROUTE, { enable: status }, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((scim: Scim) => {
      dispatch(setScim(scim))
    })
    .catch(() => {
      dispatch(setScim({ enabled: false, scimUrl: '', authHeader: '' }))
    })
}

export const fetchMdmStatus = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(MDM_ROUTE, dispatch)
    .then((mdm: Mdm) => {
      dispatch(setMdm(mdm))
    })
    .catch(() => {
      dispatch(setMdm({ enabled: false, token: '' }))
    })
}

export const updateMdmStatus = (status: boolean) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(MDM_ROUTE, { enable: status }, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((mdm: Mdm) => {
      dispatch(setMdm(mdm))
    })
    .catch(() => {
      dispatch(setMdm({ enabled: false, token: '' }))
    })
}

export const fetchEmailPreferences = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(EMAIL_PREFERENCES_ROUTE, dispatch)
    .then((emailPreferences: EmailPreferences) => {
      dispatch(setEmailPreference(emailPreferences))
    })
    .catch(() => {
      dispatch(setEmailPreference(undefined))
    })
}

export const fetchThreatHunterUsers = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(THREAT_HUNTER_USERS_ROUTE, dispatch)
    .then((threatHunterUsers: ThreatHunterUsersResponse) => {
      dispatch(setThreatHunterUsers(threatHunterUsers.threatHunterUsers))
      return threatHunterUsers
    })
    .catch((error) => {
      dispatch(setThreatHunterUsers(undefined))
      return { error: error }
    })
}

export const fetchNotificationPreferences = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(NOTIFICATION_PREFERENCES_ROUTE, dispatch)
    .then((notificationPreferences: NotificationPreferences) => {
      dispatch(setNotificationPreference(notificationPreferences))
    })
    .catch(() => {
      dispatch(setNotificationPreference(undefined))
    })
}

export const addThreatHunterUser = (user: AddThreatHunterUser) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(ADD_THREAT_HUNTER_USER_ROUTE, user, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((threatHunterUsers: ThreatHunterUsersResponse) => {
      dispatch(setThreatHunterUsers(threatHunterUsers.threatHunterUsers))
      return threatHunterUsers
    })
    .catch((error) => {
      dispatch(setThreatHunterUsers(undefined))
      return { error: error }
    })
}

export const putEmailPreferences = (payload: EmailPreferences) => async (dispatch: CustomThunkDispatch) => {
  return server
    .put(EMAIL_PREFERENCES_ROUTE, payload, dispatch)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export const putNotificationPreferences =
  (payload: NotificationPreferences) => async (dispatch: CustomThunkDispatch) => {
    return server
      .put(NOTIFICATION_PREFERENCES_ROUTE, payload, dispatch)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

export const fetchEnrollmentEmailTemplate = () => async (dispatch: CustomThunkDispatch) => {
  await server
    .get(ENROLLMENT_EMAIL_TEMPLATE_ROUTE, dispatch)
    .then((enrollEmailTemplate: EnrollEmailTemplate) => {
      dispatch(setEmailEnrollmentTemplate(enrollEmailTemplate))
    })
    .catch(() => {
      dispatch(setEmailEnrollmentTemplate(undefined))
    })
}

export const putEnrollmentEmailTemplate = (payload: EnrollEmailTemplate) => async (dispatch: CustomThunkDispatch) => {
  return server
    .put(PUT_ENROLLMENT_EMAIL_TEMPLATE_ROUTE, payload, dispatch)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export const fetchConditionalAccessConfig = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(CONDITIONAL_ACCESS_CONFIG_ROUTE, dispatch)
    .then((conditionalAccess?: ConditionalAccessResponse) => {
      return conditionalAccess
    })
    .catch(() => {
      return undefined
    })
}

export const postConditionalAccessConfig =
  (payload: ConditionalAccessResponse) => async (dispatch: CustomThunkDispatch) => {
    return server
      .post(CONDITIONAL_ACCESS_CONFIG_ROUTE, payload, dispatch)
      .then((res) => {
        if (res?.status === 202) {
          return { error: undefined }
        }
        return res?.json().then((data) => {
          if (!res.ok) {
            return { error: data.error || 'Unknown error' }
          } else {
            return { error: undefined }
          }
        })
      })
      .catch((error) => {
        return { error: error.message }
      })
  }

export const fetchConditionalAccessPolicy = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .get(CONDITIONAL_ACCESS_POLICY_ROUTE, dispatch)
    .then((conditionalAccess: ConditionalAccessPolicyResponse) => {
      return conditionalAccess
    })
    .catch(() => {
      return undefined
    })
}

export const putConditionalAccessPolicy =
  (payload: ConditionalAccessPolicyResponse) => async (dispatch: CustomThunkDispatch) => {
    const updatedPayload = {
      ...payload,
      screenLockEnabled: payload.screenLockEnabled === 'ENABLED' ? true : false,
      smishingEnabled: payload.smishingEnabled === 'ENABLED' ? true : false,
      biometricsEnabled: payload.biometricsEnabled === 'ENABLED' ? true : false,
    }

    return server
      .put(CONDITIONAL_ACCESS_POLICY_ROUTE, updatedPayload, dispatch)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

export const deleteConditionalAccess = () => async (dispatch: CustomThunkDispatch) => {
  return server
    .delete(CONDITIONAL_ACCESS_DELETE_ROUTE, dispatch)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export const postDisableConditionalAccess =
  (payload: ConditionalAccessResponse) => async (dispatch: CustomThunkDispatch) => {
    return server
      .post(CONDITIONAL_ACCESS_CONFIG_ROUTE, payload, dispatch)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }
