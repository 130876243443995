export const navItems = [
  {
    type: 'DASHBOARD',
    display: 'Dashboard',
  },
  {
    type: 'ACTIVITY',
    display: 'Activity',
  },
  {
    type: 'ALERTS',
    display: 'Alerts',
  },
  {
    type: 'DEVICES',
    display: 'Devices',
  },
  {
    type: 'MEMBERS',
    display: 'Members',
  },
  { type: 'BLOCKLIST', display: 'Domain Blocklist' },
]
