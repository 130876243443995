export interface Members {
  members: Member[]
  totalPages: number
}

export interface Member {
  id: string
  name: string
  email: string
  createdAt: string
  devices: MemberDevice[]
  isBlocked?: boolean
  blockedByPolicy?: string
  failedToBlock?: boolean
  gracePeriod?: string
}

export interface MemberDevice {
  id: number
  code: string
  memberId: number
  identifier: string
  model: string
  status: string
  platform: string
  fcmToken: string
  createdAt: string
  deviceName?: string
}

export interface MemberPayload {
  name: string
  email: string
  id?: string
}

export interface AddMemberResponse {
  error?: string
}

export interface MemberSelectOption {
  selected: number
}

export enum MemberConditionalAccessState {
  ALL = 'all',
  COMPLIANT = 'compliant',
  BLOCKED = 'blocked',
  FAILED = 'failed',
}
export type addMemberInputs = {
  name: string
  email: string
}

export enum GracePeriod {
  ONE_HOUR = 'ONE_HOUR',
  FOUR_HOURS = 'FOUR_HOURS',
  ONE_DAY = 'ONE_DAY',
  THREE_DAYS = 'THREE_DAYS',
  ONE_WEEK = 'ONE_WEEK',
}
